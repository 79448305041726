import { MenuEntry } from '@pancakeswap-libs/uikit-v4'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },                
    ],
  },
  {
    label: 'My Pools',
    icon: 'MoreIcon',
    href: '/my-pools',
  },    
  {
    label: 'Farming',
    icon: 'MoreIcon',
    href: '/farms',
  },
  {
    label: 'Staking',
    icon: 'MoreIcon',
    href: '/stake',
  }, 
  {
    label: 'Staking-Old',
    icon: 'MoreIcon',
    href: '/stake-old',
  },       
  {
    label: 'Unbondings',
    icon: 'MoreIcon',
    href: '/unbondings',
  },
  {
    label: 'Lottery',
    icon: 'MoreIcon',
    href: '/lottery',
  },     
  {
    label: 'Smart Contracts',
    icon: 'MoreIcon',
    href: '/smart-contracts',
  },
  {
    label: 'Keplr Connect',
    icon: 'MoreIcon',
    href: '/keplr',
  },   
  {
    label: 'CNTO Utility',
    icon: 'MoreIcon',
    href: '/cnto-utility',
  },    
  {
    label: 'Road Map',
    icon: 'MoreIcon',
    href: '/road-map',
  },          
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/CientoExchange',
  },
  {
    label: 'Discord',
    icon: 'MoreIcon',
    href: 'https://discord.gg/gN85SWQGax',
  },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/CientoExchange',
  },      
]

export default config
