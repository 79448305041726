export const addresses = {
    "0":
    {
        address: '0x18294B4F10c2fe782B0faE8c7DaB7028426ACe65',
    },
    "1":
    {
        address: '0x000dEf3aE1c6E5119810a760542c478B73a74E93',
    },
    "2":
    {
        address: '0xa51AB45604f7f76734faBB4404DAD23899a302c7',
    },
    "3":
    {
        address: '0xeBB5366Cc83eE5bE598a36b7A4DF088abe055Ca6',
    },
    "4":
    {
        address: '0x212C0610968caB68573323436f241fB4f6Fa9C1E',
    },
    "5":
    {
        address: '0x10ebeBc1c937e20998A516C13f507983A8Fad355',
    },
    "6":
    {
        address: '0x720eae3B35D3CD36933bDE52aE2c3776098c3b37',
    },
    "7":
    {
        address: '0xc325F85612082Ba93E7b7781D7c1a4209605ED9f',
    },
    "8":
    {
        address: '0xbccBc1210fC0E36F9c642B3e60E9281EAE084035',
    },
    "9":
    {
        address: '0x26cE2A07C8Df1e5769F6ee1f4E1ed58239782464',
    },
    "10":
    {
        address: '0x3C51Abc30710B133812BAA484593D85B3F9bAF3D',
    },
    "11":
    {
        address: '0x668Ac14fAD9e59017abC1f9742c504B26874E782',
    },
    "12":
    {
        address: '0x412bf9D0937dAFc996e16A1F468E41fB074E072C',
    },
    "13":
    {
        address: '0x2077b8C5231CAfC0664841C28A1c531174d70DE1',
    },
    "14":
    {
        address: '0x1204B302942598157B89424aEd17080EDa5b27Ea',
    },
    "15":
    {
        address: '0x5Aa05950B1be4073581405b83330dB30B8260e33',
    },
    "16":
    {
        address: '0x9B6Bd7D9b480249d11678DCc955A2152a0C2E879',
    },
    "17":
    {
        address: '0xc76E6c6031A2766B3b6e789241212Bc99468d822',
    },
    "18":
    {
        address: '0xef988ddcb4089E4CA6d89EdEFbab1B11208B4e40',
    },
    "19":
    {
        address: '0x7Ce91492d9D3c102E46a218Ee74bB093f63190C3',
    },
    "20":
    {
        address: '0x4Cac79065cba792E25c0fEfa45810370A6f5b570',
    },
    "21":
    {
        address: '0x0e9B759e05Cdde29BaB3ba1745d469E8BaBA9fB3',
    },                      
    stake_token:
    {
        address: '0xAE6D3334989a22A65228732446731438672418F2',
    },    
    farm:
    {
        address: '0x562Dd9Cd2951e4bCa7a6e424aaB67b0e30Eb624e',
    },
    stake:
    {
        address: '0xBb1287CA8d7dA4109f4DB91c0B2332ddE24286E3',
    }, 
    stake_old:
    {
        address: '0xdee7733F53839Ab13865ac0E8196F521EB44B204',
    },       
    locker:
    {
        address: '0x8B68757eE51249307F8D757Ac437C9463B30120d',
    },
    lottery:
    {
        address: '0x703491F1E66895225B2f16752fE46E861Ee0A1f3',
    },     
}