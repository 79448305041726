import React from 'react';

const TruncatedString = ({ inputString }) => {
  const firstFiveChars = inputString.substring(0, 5);
  const lastFiveChars = inputString.substring(inputString.length - 5);

  return (
    <div>
      <p>{firstFiveChars}.....{lastFiveChars}</p>
    </div>
  );
};

export default TruncatedString;