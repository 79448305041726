import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { addresses } from '../components/Assets/addresses';
import farmABI from '../components/abi/farmContractABI.json'
import tokenABI from '../components/abi/tokenABI.json';
import { values } from '../components/Assets/values';

const Farms3 = () => {


  function CommaFormatted(amount) {
    var delimiter = ","; // replace comma if desired
    var a = amount.split('.',2)
    var d = a[1];
    var i = parseInt(a[0]);
    if(isNaN(i)) { return ''; }
    var minus = '';
    if(i < 0) { minus = '-'; }
    i = Math.abs(i);
    var n = new String(i);
    var a = [];
    while(n.length > 3) {
      var nn = n.substr(n.length-3);
      a.unshift(nn);
      n = n.substr(0,n.length-3);
    }
    if(n.length > 0) { a.unshift(n); }
    n = a.join(delimiter);
    if(d.length < 1) { amount = n; }
    else { amount = n + '.' + d; }
    amount = minus + amount;
    return amount;
  }  

    const live = true;

  const farmAddress = addresses.farm.address;

  const contractAddress = "";
  const [chainID, setChainID] = useState();
  const [address, setAddress] = useState('');
  const [accbalance, setBalance] = useState();
  const [chainError, setChainError] = useState(false);
  const [pZeroAPR, setpZeroAPR] = useState('');
  const [pZeroAPRTwo, setpZeroAPRTwo] = useState('');
  const [pOneAPR, setpOneAPR] = useState('');
  const [pTwoAPR, setpTwoAPR] = useState('');
  const [pThreeAPR, setpThreeAPR] = useState('');
  const [pFourAPR, setpFourAPR] = useState('');
  const [pFiveAPR, setpFiveAPR] = useState('');
  const [pSixAPR, setpSixAPR] = useState('');
  const [pEighteenAPR, setpEighteenAPR] = useState('');
  const [pNineteenAPR, setpNineteenAPR] = useState('');

  const [grid, setGrid] = useState(true);
  const [rows, setRows] = useState(false);  

  const provider = new ethers.providers.Web3Provider(window.ethereum as any);
  const signer = provider.getSigner();
  const farmContract = new ethers.Contract(farmAddress, farmABI, signer);

  useEffect(() => {

    const apr = async () => {

      let response = await fetch('https://testnet.ciento.exchange/prices');
      let data = await response.json();
      var acrePrice = data[0].price;
      var atomPrice = data[2].price;
      var osmoPrice = data[3].price;
      var wbnbPrice = data[8].price;
      var wavaxPrice = data[11].price;
      var wmaticPrice = data[12].price;
      var cntoPrice = data[13].price;
      var statomPrice = data[16].price;
      var stosmoPrice = data[17].price;
      var usdcPrice = 1;

      console.log("test1");

      //if(account) {


        var totalAllocPoint = await farmContract.totalAllocPoint();
        console.log("TotalAllocPoint = ", totalAllocPoint.toString());
        totalAllocPoint = totalAllocPoint.toString();
        var totalAllocPointNew = 2340;


        ///////////// Pool 18 CNTO/stATOM ////////////////////

        var tokenAddress = addresses[18].address;
        console.log("tokenAddress = ", tokenAddress);
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var statomTotal = (result[0].toString() / 1000000);
        var cntoTotal = (result[1].toString() / 1000000000000000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var cntoSingle = cntoTotal / lpTotal;
        var statomSingle = statomTotal / lpTotal;
        var cntoUSD = (cntoSingle * userLP) * cntoPrice;
        var statomUSD = (statomSingle * userLP) * statomPrice;
        var lpUSD = cntoUSD + statomUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        //var allocPoint = await farmContract.poolInfo(18);
        //console.log("allocPoint = ", allocPoint[1].toString());
        //allocPoint = allocPoint[1].toString();
        var allocPointNew = 200;

        var myAPR = (((perday * (allocPointNew / totalAllocPointNew)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpEighteenAPR(CommaFormatted(myAPR.toFixed(2)));   
        



        
       ///////////// Pool 19 CNTO/stOSMO ////////////////////

       var tokenAddress = addresses[19].address;
       console.log("tokenAddress = ", tokenAddress);
       var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
       var result = await tokenContract.getReserves();
       var cntoTotal = (result[0].toString() / 1000000000000000000);
       var stosmoTotal = (result[1].toString() / 1000000);
       result = await tokenContract.totalSupply();       
       var lpTotal = (result.toString() / 1000000000000000000);

       //var userLP = await farmContract.getDepositAmount(account, 1);
       var userLP = await tokenContract.balanceOf(farmAddress);
       userLP = userLP / 1000000000000000000;
       var cntoSingle = cntoTotal / lpTotal;
       var stosmoSingle = stosmoTotal / lpTotal;
       var cntoUSD = (cntoSingle * userLP) * cntoPrice;
       var stosmoUSD = (stosmoSingle * userLP) * stosmoPrice;
       var lpUSD = cntoUSD + stosmoUSD;
       var perday = 1666.66 * cntoPrice;

       //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

       console.log("test2");

       //var allocPoint = await farmContract.poolInfo(18);
       //console.log("allocPoint = ", allocPoint[1].toString());
       //allocPoint = allocPoint[1].toString();
       var allocPointNew = 200;

       var myAPR = (((perday * (allocPointNew / totalAllocPointNew)) / lpUSD) * 365) * 100;
       console.log("myAPR = ", myAPR.toFixed(2));
       setpNineteenAPR(CommaFormatted(myAPR.toFixed(2)));   
       
       



        ///////////// Pool 0 ////////////////////

        var tokenAddress = addresses[0].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var acreTotal = (result[0].toString() / 1000000000000000000);
        var cntoTotal = (result[1].toString() / 1000000000000000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var cntoSingle = cntoTotal / lpTotal;
        var acreSingle = acreTotal / lpTotal;
        var cntoUSD = (cntoSingle * userLP) * cntoPrice;
        var acreUSD = (acreSingle * userLP) * acrePrice;
        var lpUSD = cntoUSD + acreUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(0);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpZeroAPR(CommaFormatted(myAPR.toFixed(2)));



        ///////////// Pool 6 CNTO/USDC ////////////////////

        var tokenAddress = addresses[6].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var cntoTotal = (result[0].toString() / 1000000000000000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var cntoSingle = cntoTotal / lpTotal;
        var usdcSingle = usdcTotal / lpTotal;
        var cntoUSD = (cntoSingle * userLP) * cntoPrice;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var lpUSD = cntoUSD + usdcUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(6);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpSixAPR(CommaFormatted(myAPR.toFixed(2)));




        ///////////// Pool 1 CNTO/ATOM ////////////////////

        var tokenAddress = addresses[1].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var atomTotal = (result[0].toString() / 1000000);
        var cntoTotal = (result[1].toString() / 1000000000000000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var cntoSingle = cntoTotal / lpTotal;
        var atomSingle = atomTotal / lpTotal;
        var cntoUSD = (cntoSingle * userLP) * cntoPrice;
        var atomUSD = (atomSingle * userLP) * atomPrice;
        var lpUSD = cntoUSD + atomUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(1);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpOneAPR(CommaFormatted(myAPR.toFixed(2)));
        
        


        ///////////// Pool 2 CNTO/OSMO ////////////////////

        var tokenAddress = addresses[2].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var cntoTotal = (result[0].toString() / 1000000000000000000);
        var osmoTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var cntoSingle = cntoTotal / lpTotal;
        var osmoSingle = osmoTotal / lpTotal;
        var cntoUSD = (cntoSingle * userLP) * cntoPrice;
        var osmoUSD = (osmoSingle * userLP) * osmoPrice;
        var lpUSD = cntoUSD + osmoUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(2);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpTwoAPR(CommaFormatted(myAPR.toFixed(2)));  



        ///////////// Pool 3 CNTO/WBNB ////////////////////

        var tokenAddress = addresses[3].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var cntoTotal = (result[0].toString() / 1000000000000000000);
        var wbnbTotal = (result[1].toString() / 1000000000000000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var cntoSingle = cntoTotal / lpTotal;
        var wbnbSingle = wbnbTotal / lpTotal;
        var cntoUSD = (cntoSingle * userLP) * cntoPrice;
        var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
        var lpUSD = cntoUSD + wbnbUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(3);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpThreeAPR(CommaFormatted(myAPR.toFixed(2)));    



        ///////////// Pool 4 CNTO/WMATIC ////////////////////

        var tokenAddress = addresses[4].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var wmaticTotal = (result[0].toString() / 1000000000000000000);
        var cntoTotal = (result[1].toString() / 1000000000000000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var cntoSingle = cntoTotal / lpTotal;
        var wmaticSingle = wmaticTotal / lpTotal;
        var cntoUSD = (cntoSingle * userLP) * cntoPrice;
        var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
        var lpUSD = cntoUSD + wmaticUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(4);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpFourAPR(CommaFormatted(myAPR.toFixed(2)));


        ///////////// Pool 5 CNTO/WAVAX ////////////////////

        var tokenAddress = addresses[5].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var cntoTotal = (result[0].toString() / 1000000000000000000);
        var wavaxTotal = (result[1].toString() / 1000000000000000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var cntoSingle = cntoTotal / lpTotal;
        var wavaxSingle = wavaxTotal / lpTotal;
        var cntoUSD = (cntoSingle * userLP) * cntoPrice;
        var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
        var lpUSD = cntoUSD + wavaxUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(5);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpFiveAPR(CommaFormatted(myAPR.toFixed(2)));  



      //}
    
    }
      
    apr()
      .catch(console.error);       

  }, []);


  const getURLZero = () => {
    window.location.assign('/#/farm/0');
  }

  const getURLOne = () => {
    window.location.assign('/#/farm/1');
  }
  
  const getURLTwo = () => {
    window.location.assign('/#/farm/2');
  }
  
  const getURLThree = () => {
    window.location.assign('/#/farm/3');
  }  

  const getURLFour = () => {
    window.location.assign('/#/farm/4');
  }  

  const getURLFive = () => {
    window.location.assign('/#/farm/5');
  }    

  const getURLSix = () => {
    window.location.assign('/#/farm/6');
  }  

  const getURLEighteen = () => {
    window.location.assign('/#/farm/18');
  } 
  
  const getURLNineteen = () => {
    window.location.assign('/#/farm/19');
  } 

  const gridSubmit = () => {
    setGrid(true);
    setRows(false);
  } 
  
  const rowsSubmit = () => {
    setGrid(false);
    setRows(true);
  }     

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)


  return (
    <>
      <div className='farmsClass'>
      <AppBody>
        <Wrapper id="farms-page">




              <div className='buttons center'>   

                <div className='myHeader'>
                  <img src="/images/Ciento-Main.png" />
                  <p className='myTitle'>Ciento Exchange</p>
                  <p className='mySub'>CNTO Rewards for all New Pools will start from 8th March 2023.</p>  
                </div>
        
                { account ? "" :
                  <div className='myUnlockWallet'>
                    <ConnectWalletButton width="100%" />
                  </div>
                }
                
                { chainError === true ?
                  <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                  </div>
                : "" } 
        
              </div>

              { live ?
              <div className='blockMain'>

                <div className='gridrows'>
                  <img onClick={gridSubmit} src="/images/grid.png" />
                  <img onClick={rowsSubmit} src="/images/rows.png" />
                </div>


              { grid ?

              <div className='blockMain'>


                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="images/logos/statom.png" />
                    <p className='poolTitle'>CNTO-stATOM Pool</p>
                    <p className='poolText'>Deposit CNTO-stATOM LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLEighteen}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pEighteenAPR ? pEighteenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                    <p className='poolTextRed'><br></br>Rewards starts from <br></br> <u>8th March 2023</u></p>
                </div>    


                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="images/logos/stosmo.png" />
                    <p className='poolTitle'>CNTO-stOSMO Pool</p>
                    <p className='poolText'>Deposit CNTO-stOSMO LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLNineteen}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pNineteenAPR ? pNineteenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                    <p className='poolTextRed'><br></br>Rewards starts from <br></br> <u>8th March 2023</u></p>
                </div>  



                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="/images/logos/wacre.png" />
                    <p className='poolTitle'>CNTO-ACRE Pool</p>
                    <p className='poolText'>Deposit CNTO-ACRE LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLZero}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pZeroAPR ? pZeroAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>                  
                </div> 


                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="/images/logos/usdc.png" />
                    <p className='poolTitle'>CNTO-USDC Pool</p>
                    <p className='poolText'>Deposit CNTO-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLSix}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pSixAPR ? pSixAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>   

                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="/images/logos/atom.png" />
                    <p className='poolTitle'>CNTO-ATOM Pool</p>
                    <p className='poolText'>Deposit CNTO-ATOM LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLOne}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pOneAPR ? pOneAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div> 

                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="/images/logos/osmo.png" />
                    <p className='poolTitle'>CNTO-OSMO Pool</p>
                    <p className='poolText'>Deposit CNTO-OSMO LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLTwo}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pTwoAPR ? pTwoAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div> 

                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="images/logos/bnb.png" />
                    <p className='poolTitle'>CNTO-WBNB Pool</p>
                    <p className='poolText'>Deposit CNTO-WBNB LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLThree}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pThreeAPR ? pThreeAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>                                    


                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="images/logos/avax.png" />
                    <p className='poolTitle'>CNTO-WAVAX Pool</p>
                    <p className='poolText'>Deposit CNTO-WAVAX LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLFive}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pFiveAPR ? pFiveAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>  


                <div className='blocks'>
                    <img className='img_left' src="/images/logos/ciento.png" />
                    <img className='img_right' src="images/logos/matic.png" />
                    <p className='poolTitle'>CNTO-WMATIC Pool</p>
                    <p className='poolText'>Deposit CNTO-WMATIC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLFour}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pFourAPR ? pFourAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>


              </div>                

            : '' }


            { rows ? 

              <div className='blockMain mygridPools'>

                  <div className="boxGridPools mycolor">
                    <div className='item'>Pool</div>
                    <div className='item'>APR</div>
                    <div className='item'></div>
                  </div>



                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/statom.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-stATOM Pool</div>
                      <br></br><br></br>
                      <p className='poolTextRedGrid'>Rewards starts from <u>8th March 2023</u></p>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pEighteenAPR ? pEighteenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLEighteen}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>



                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/stosmo.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-stOSMO Pool</div>
                      <br></br><br></br>
                      <p className='poolTextRedGrid'>Rewards starts from <u>8th March 2023</u></p>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pNineteenAPR ? pNineteenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLNineteen}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>



                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/wacre.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-ACRE Pool</div>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pZeroAPR ? pZeroAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLZero}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>



                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/usdc.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-USDC Pool</div>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pSixAPR ? pSixAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLSix}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>




                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/atom.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-ATOM Pool</div>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pOneAPR ? pOneAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLOne}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>




                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/osmo.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-OSMO Pool</div>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pTwoAPR ? pTwoAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLTwo}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>




                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/bnb.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-WBNB Pool</div>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pThreeAPR ? pThreeAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLThree}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>




                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/avax.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-WAVAX Pool</div>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pFiveAPR ? pFiveAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLFive}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>





                  <div className="boxGridPools">
                    <div className='item'>

                      <div className='images'>
                        <img className='img_left' src="/images/logos/ciento.png" />
                        <img className='img_right' src="images/logos/matic.png" />
                      </div>
                    
                      <div className='poolTitle'>CNTO-WMATIC Pool</div>

                    </div>
                    <div className='item'>
                      <div className='poolAPR'>{pFourAPR ? pFourAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                    </div>
                    <div className='item'>
                      { account ? <button onClick={getURLFour}>Select</button>
                        : <button disabled className='disabled'>Select</button> }   
                    </div>
                  </div>

              </div>            
            
            : '' }
            




              </div>
             : <div></div> }  





        </Wrapper>
      </AppBody>
      </div>
    </>
  )
}

export default Farms3
