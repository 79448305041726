import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { addresses } from '../components/Assets/addresses';
import lotteryABI from '../components/abi/lotteryContractABI.json'
import tokenABI from '../components/abi/tokenABI.json';
import { values } from '../components/Assets/values';
import { months } from '../components/Assets/months';
import Countdown from './Countdown';
import TruncatedString from './TruncatedString';

const Lottery = () => { 


    function CommaFormatted(amount) {
        var delimiter = ","; // replace comma if desired
        var a = amount.split('.',2)
        var d = a[1];
        var i = parseInt(a[0]);
        if(isNaN(i)) { return ''; }
        var minus = '';
        if(i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while(n.length > 3) {
          var nn = n.substr(n.length-3);
          a.unshift(nn);
          n = n.substr(0,n.length-3);
        }
        if(n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if(d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + amount;
        return amount;
      }    

  function expo(x, f) {
    return Number.parseFloat(x).toExponential(f);
  }

  function counter(id, start, end, duration) {
    let obj = document.getElementById(id),
     current = start,
     range = end - start,
     increment = end > start ? 1 : -1,
     step = Math.abs(Math.floor(duration / range)),
     timer = setInterval(() => {
      current += increment;
      obj.textContent = current + '.000';
      if (current > end) {
       clearInterval(timer);
       obj.textContent = end;
      }
     }, step);
  }

  const ticketPrice = 20;

  const { account } = useActiveWeb3React();

  const [chainError, setChainError] = useState(false);
  const [winningAddress, setWinningAddress] = useState('');

  const [depositFlag, setDepositFlag] = useState(false);
  const [depositTime, setDepositTime] = useState('');
  const [lotteryTime, setLotteryTime] = useState('');
  const [withdrawFlag, setWithdrawFlag] = useState(true);
  const [lotteryFlag, setLotteryFlag] = useState(false);
  const [lotteryNumber, setLotteryNumber] = useState('0');
  const [tokenbalance, setTokenBalance] = useState('');
  const [userBalance, setUserBalance] = useState('');
  const [totalBalance, setTotalBalance] = useState('');
  const [userTickets, setUserTickets] = useState('');
  const [userNoTickets, setUserNoTickets] = useState('');
  const [totalTickets, setTotalTickets] = useState('');
  const [userPercentage, setUserPercentage] = useState('');
  const [stakeOnValue, setStakeOnValue] = useState('');
  const [unstakeOnValue, setUnstakeOnValue] = useState('');
  const [allowance, setAllowance] = useState('');
  const [allowanceFlag, setAllowanceFlag] = useState(false);
  const [approve, setApprove] = useState(false);
  const [spinner, setSpinnerVal] = useState('');
  const [spinner_claim, setSpinnerClaimVal] = useState('');
  const [success, setSuccess] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [valerror, setError] = useState('');
  const [txValue, setTxValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [executeVal, setExecuteVal] = useState('');
  const [execute, setExecute] = useState(false);
  const [stakeInputVal, setStakeInputVal] = useState('');


  const lotteryAddress = addresses.lottery.address;
  const tokenAddress = addresses.stake_token.address;

  const provider = new ethers.providers.Web3Provider(window.ethereum as any);
  const signer = provider.getSigner();
  const lotteryContract = new ethers.Contract(lotteryAddress, lotteryABI, signer);
  const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);

  useEffect(() => {

    const connectMetaMask = async () => {
      if(account) 
        getAccount();
    }
      
    connectMetaMask()
      .catch(console.error);       

  }, []);  

  const getAccount = async () => {

    var currentLottery = await lotteryContract.lotteryCount();
    console.log("Current Lottery = ", currentLottery.toString());
    setLotteryNumber(currentLottery.toString());
    
    var endTime = await lotteryContract.lotteryEndTime();
    console.log("endTime = ", endTime.toString());

    if(endTime.toString() > 0 && Date.now() < endTime.toNumber() * 1000)
    {
        setLotteryFlag(true);
        var lNum = await lotteryContract.lotteryCount();
        setLotteryNumber(lNum.toString());
        setWithdrawFlag(false);
    }

    var winDetails = await lotteryContract.lotteries(currentLottery.toString());
    setWinningAddress(winDetails[1]);

    var depositendTime = await lotteryContract.depositEndTime();
    console.log("depositendTime = ", depositendTime.toString());
    setDepositTime(depositendTime.toString());

    var lotteryendTime = await lotteryContract.lotteryEndTime();
    console.log("lotteryendTime = ", lotteryendTime.toString());
    setLotteryTime(lotteryendTime.toString());  

    if(depositendTime.toString() > 0 && Date.now() < depositendTime.toNumber() * 1000)
    {
        setDepositFlag(true);
    }    

    const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
    var balance = await tokenContract.balanceOf(lotteryAddress);
    console.log("Total Balance = ", balance.toString());
    setTotalBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(0));

    balance = await lotteryContract.balances(account);
    console.log("User Balance = ", balance.toString());
    setUserBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(0));

    if(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])) === 0)
        setWithdrawFlag(false);

    var chances = "0";
    if(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])) > 0)
    {
        try {
          chances = await lotteryContract.getMyChances(account);
          console.log("chances = ", chances.toString());
        } catch (error) {
          chances = "0";
          console.log("chances = ", chances.toString());
          console.error(error);
        }
    }
    setUserPercentage(chances.toString());
    
    balance = await tokenContract.balanceOf(account);
    console.log("Total Balance = ", balance.toString());
    setTokenBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(0));    

    balance = await lotteryContract.totalTicketsSold();
    console.log("Total Tickets = ", balance.toString());
    setTotalTickets(balance.toString());
    
    var tickets = "0";
    var data = await lotteryContract.getMyTickets(account);
    console.log("Length = ", data.length);
    setUserNoTickets(data.length);
    if(data.length > 0)
    {
        for (var i = 0; i < data.length; i++) {
          if(i === 0)
            tickets = data[i] + ', ';
          else if(i === (data.length - 1))
            tickets = tickets + data[i];
          else
            tickets = tickets + data[i] + ', ';
        }
    }
    setUserTickets(tickets);

    console.log("Tickets = ", tickets);

  }
  
  
  const getAllowance = async () => {
    const allowance = await tokenContract.allowance(signer.getAddress(), lotteryAddress);
    setAllowance(allowance.toString());
    if(allowance.gt(ethers.utils.parseUnits(stakeInputVal, values['decimals'])) || allowance.eq(ethers.utils.parseUnits(stakeInputVal, values['decimals'])))
    {
      setAllowanceFlag(true);
      setSpinnerVal('');
      setApprove(false);          
    }
  }   
  
  const stakeOn = () => {
    console.log("Inside");
    if(stakeOnValue === '')
    {
      setStakeOnValue('1');
      setUnstakeOnValue('');
      setClaimSuccess(false);
      setSuccess(false);
    }
    else
    {
      setStakeOnValue('');
      setUnstakeOnValue('');
      setClaimSuccess(false);
      setSuccess(false);
    }
  }

  const handleBuy = async (e) => {
    e.preventDefault();
    setError('');
    if(!userBalance)
    {
      setError('No Tickets.');
    }
    else if(+userBalance / ticketPrice === +userNoTickets)
    {
      setError('No Tickets.');
    }
    else
    {  
      setError(''); 
      setSpinnerVal('spinner-border spinner-border-sm mr-1');
      setApprove(true); 

      try {
        setStakeOnValue('');
        setUnstakeOnValue('');
        const result = await lotteryContract.buyTicket();
        await result.wait();
        setClaimSuccess(true);
        setTxValue(values['explorer'] + "tx/" + result.hash);
        setApprove(false);
        setSpinnerClaimVal('');
        getAccount();
      } catch (error) {
        console.error(error);
      }


    }        
  }        

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    setStakeInputVal(event.target.value);
  };


  const handleApprove = async (e) => {
    e.preventDefault();
    setError('');
    //setStakeInputVal(e.target.value);
    console.log("stakeInputVal = ", stakeInputVal);
    if(!stakeInputVal)
    {
      setError('Please enter correct value.');
    }
    else if(stakeInputVal.length === 0 || +stakeInputVal === 0)
    {
      setError('Please enter correct value.');
    }
    else if(ethers.utils.parseUnits(stakeInputVal, values['decimals']).gt(ethers.utils.parseUnits(tokenbalance, values['decimals'])))
    {
      setError('Not enough balance.');
    }
    else
    {
      try {
        setError(''); 
        setSpinnerVal('spinner-border spinner-border-sm mr-1');
        setApprove(true); 
        const tokenVal = ethers.utils.parseUnits(stakeInputVal, values['decimals']);
        const result = await tokenContract.approve(lotteryAddress, tokenVal);
        await result.wait();
        setAllowance(stakeInputVal);
        getAllowance();
        setStakeInputVal(stakeInputVal);
      } catch (error) {
        console.error(error);
      }            
    }        
  }  


  const handleStake = async (e) => {
    e.preventDefault();

    console.log("stakeValue = ", stakeInputVal);
    
    if(!stakeInputVal)
    {
      setError('Please enter correct value.');
    }
    else if(+stakeInputVal == 0)
    {
      setError('Please enter correct value.');
    }        
    else if(stakeInputVal.length === 0 || +stakeInputVal === 0)
    {
      setError('Please enter correct value.');
    }
    else if(ethers.utils.parseUnits(stakeInputVal, values['decimals']).gt(ethers.utils.parseUnits(tokenbalance, values['decimals'])))
    {
      setError('Not enough balance.');
    }
    else
    {
      setExecute(true);
      setError('');
      setExecuteVal('spinner-border spinner-border-sm mr-1');

      try {
        var tokenVal = ethers.utils.parseUnits(stakeInputVal, values['decimals']);
        const total = await tokenContract.balanceOf(account);
        if(total.lt(tokenVal))
          tokenVal = total; 
        const result = await lotteryContract.deposit(tokenVal);
        await result.wait();
        setSuccess(true);
        setTxValue(values['explorer'] + "tx/" + result.hash);
        setExecute(false);
        setExecuteVal('');
        getAccount();
      } catch (error) {
        console.error(error);
      }

    }
  }  



  const handleUnstake = async (e) => {
      e.preventDefault();
      setExecute(true);
      setError('');
      setExecuteVal('spinner-border spinner-border-sm mr-1');

      try {
        const result = await lotteryContract.withdraw();
        await result.wait();
        setSuccess(true);
        setTxValue(values['explorer'] + "tx/" + result.hash);
        setExecute(false);
        setExecuteVal('');
        getAccount();
      } catch (error) {
        console.error(error);
      }
  }  

 
  const theme = useContext(ThemeContext)


  return (
    <>
      <div className='farmsClass'>
      <AppBody>
        <Wrapper id="farms-page">
          

        <div className='buttons center'>   

                <div className='myHeader'>
                    <img src="/images/Ciento-Main.png" />
                    <p className='myTitle'>Ciento Exchange - Lottery</p>

                    { lotteryFlag ?
                        <p className='mySub'>Lottery No. { lotteryNumber } is LIVE!!</p>
                        : <p className='mySub'>Lottery No. { lotteryNumber } is Closed!</p>
                    }

                </div>

                { account ? "" :
                <div className='myUnlockWallet'>
                    <ConnectWalletButton width="100%" />
                </div>
                }

                { chainError === true ?
                <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                </div>
                : "" } 

        </div>

        { account ?
        <div className='blockMain'>

            <div className='myAPRLottery'>
                <p>Prize: 250 USDC</p>         
            </div>

            { !lotteryFlag && winningAddress != "0x0000000000000000000000000000000000000000" ?
              <div className="winningAddress">Lottery No. {lotteryNumber} Winning Addr: <TruncatedString inputString={winningAddress} /></div>
            : '' }

            <div className='blockMain'>
                <div className='blockShort'>
                    <div className='inside'>
                        <div className='mcapValue'>{ totalBalance ? totalBalance + ' CNTO': <span className="myloadergreen"></span> }</div>
                        <div className='mcapTitle'>Total CNTO Deposits</div>
                    </div>
                </div>
                <div className='blockShort'>
                    <div className='inside'>
                        <div className='mcapValue'>{ totalTickets ? totalTickets + " Tickets" : <span className="myloadergreen"></span> }</div>
                        <div className='mcapTitle'>Total Tickets Generated</div>
                    </div>
                </div>            
                <div className='blockShort'>
                    <div className='inside'>
                        <div className='mcapValue'>{ userPercentage ? userPercentage + ' %' : <span className="myloadergreen"></span> }</div>
                        <div className='mcapTitle'>Your Chances of Winning</div>
                    </div>
                </div>                
            </div> 

            <br></br>

            <div className='myAPRLottery'>
              { depositFlag ?
                <p>Deposits Ends in <Countdown unixTimestamp={depositTime} /></p> 
              : <p>Deposits Time Ended.</p> }
            </div>
            <div className='myAPRLottery'>
              { lotteryFlag ?
                <p>Lottery Ends in <Countdown unixTimestamp={lotteryTime} /></p>
              : <p>Lottery Ended.</p> }
            </div> 

            <br></br>

            <div className='blocks myblock'>
                <img src={values[tokenAddress].img} />
                <p className='rewardsBalance'><p id='sBalance'>{userBalance} {values[tokenAddress].name}</p> <span>Your Deposit</span></p>
                    <div className='farmButtons'>
                    <button className='left' disabled={ depositFlag ? false : true } onClick={stakeOn}>Deposits</button>
                    <button className='right' disabled={ withdrawFlag ? false : true } onClick={handleUnstake}>Withdraw</button>
                    </div>
            </div>  


            <div className='blocks myblock'>
                <img src={values[tokenAddress].img} />
                <p className='rewardsBalance'><p id='sBalance'>{userNoTickets}</p> <span>Your Total Tickets</span></p>
                <div className='farmButtons'>
                    {+userBalance / ticketPrice > +userNoTickets && lotteryFlag ? <button type="button" disabled={approve ? true : false } onClick={handleBuy} className="btn btn-primary claim"> <span className={spinner}></span> Get Tickets</button> : <button type="button" disabled className="btn btn-primary claim"> Get Tickets</button>
                    }
                </div>
            </div> 

            <br></br>

            { userTickets != '0' ?
              <div className='myAPRLottery'>
              <p>Your Ticket Numbers: { userTickets } </p>
              </div>
            : ''}

            { claimSuccess ? 
            <div className='myStake'>
            <div className='success'>
                <p className='txHead'>Success!</p>
                <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
            </div>
            </div>
            : "" }


        { stakeOnValue ?
        <form onSubmit={handleStake} >  
        
        <div className='myStake'>
          <p className='stakeTitle'>Stake {values[tokenAddress].name} Tokens
            <span>Available: {tokenbalance}</span>
          </p>
          <div className='stakeInput'>
            <span className="spanInput">

                <select value={selectedOption} onChange={handleChange}>
                    <option value="">--Please Select--</option>
                    <option value="20">20 CNTO - 1 Ticket</option>
                    <option value="40">40 CNTO - 2 Tickets</option>
                    <option value="60">60 CNTO - 3 Tickets</option>
                    <option value="80">80 CNTO - 4 Tickets</option>
                    <option value="100">100 CNTO - 5 Tickets</option>
                    <option value="120">120 CNTO - 6 Tickets</option>
                    <option value="140">140 CNTO - 7 Tickets</option>
                    <option value="160">160 CNTO - 8 Tickets</option>
                    <option value="180">180 CNTO - 9 Tickets</option>
                    <option value="200">200 CNTO - 10 Tickets</option>
                    <option value="220">220 CNTO - 11 Ticket</option>
                    <option value="240">240 CNTO - 12 Ticket</option>
                    <option value="260">260 CNTO - 13 Ticket</option>
                    <option value="280">280 CNTO - 14 Ticket</option>
                    <option value="300">300 CNTO - 15 Ticket</option>
                    <option value="320">320 CNTO - 16 Ticket</option>
                    <option value="340">340 CNTO - 17 Ticket</option>
                    <option value="360">360 CNTO - 18 Ticket</option>
                    <option value="380">380 CNTO - 19 Ticket</option>
                    <option value="400">400 CNTO - 20 Ticket</option>
                </select>

            </span>
            <span className="spanInput">{values[tokenAddress].name}</span>
          </div>
          <div>{valerror ? <span className='warning'>{valerror}</span> : '' }</div>
          

          <div className='farmButtons'>
          {(allowanceFlag ? <button type="submit" disabled={execute ? true : false } className="btn btn-primary confirm"><span className={executeVal}></span> Submit</button>: <button type="button" disabled={approve ? true : false } onClick={handleApprove} className="btn btn-primary blue"> <span className={spinner}></span> Approve</button>)}
          </div>        

          { success ? 
            <div className='success'>
              <p className='txHead'>Success!</p>
              <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
            </div>
    
            : "" }           

        </div>   

        </form>  

        : "" }




        </div>
        
        : '' }



        </Wrapper>
      </AppBody>
      </div>
    </>
  )
}

export default Lottery
