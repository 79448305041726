export const values = {   
    "0x18294B4F10c2fe782B0faE8c7DaB7028426ACe65":
    {
        name: 'CNTO-ACRE LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/wacre.png',
    },
    "0x43b78D609f0eb25D90e0e5FD069469382a173E75":
    {
        name: 'CNTO-ACRE LP',
        img: '/images/logos/ciento.png',
    },    
    "0xAE6D3334989a22A65228732446731438672418F2":
    {
        name: 'CNTO',
        img: '/images/logos/ciento.png',
    }, 
    "0x000dEf3aE1c6E5119810a760542c478B73a74E93":
    {
        name: 'CNTO-ATOM LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/atom.png',
    },
    "0xa51AB45604f7f76734faBB4404DAD23899a302c7":
    {
        name: 'CNTO-OSMO LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/osmo.png',
    },
    "0xeBB5366Cc83eE5bE598a36b7A4DF088abe055Ca6":
    {
        name: 'CNTO-WBNB LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/bnb.png',
    },
    "0x212C0610968caB68573323436f241fB4f6Fa9C1E":
    {
        name: 'CNTO-WMATIC LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/matic.png',
    },
    "0x10ebeBc1c937e20998A516C13f507983A8Fad355":
    {
        name: 'CNTO-WAVAX LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/avax.png',
    },
    "0x720eae3B35D3CD36933bDE52aE2c3776098c3b37":
    {
        name: 'CNTO-USDC LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/usdc.png',
    },
    "0xc325F85612082Ba93E7b7781D7c1a4209605ED9f":
    {
        name: 'ACRE-USDC LP',
        img: '/images/logos/wacre.png',
        img2: '/images/logos/usdc.png',
    },  
    "0xbccBc1210fC0E36F9c642B3e60E9281EAE084035":
    {
        name: 'WBTC-USDC LP',
        img: '/images/logos/btc.png',
        img2: '/images/logos/usdc.png',
    },  
    "0x26cE2A07C8Df1e5769F6ee1f4E1ed58239782464":
    {
        name: 'WETH-USDC LP',
        img: '/images/logos/eth.png',
        img2: '/images/logos/usdc.png',
    },  
    "0x3C51Abc30710B133812BAA484593D85B3F9bAF3D":
    {
        name: 'ATOM-USDC LP',
        img: '/images/logos/atom.png',
        img2: '/images/logos/usdc.png',
    },  
    "0x668Ac14fAD9e59017abC1f9742c504B26874E782":
    {
        name: 'OSMO-USDC LP',
        img: '/images/logos/osmo.png',
        img2: '/images/logos/usdc.png',
    },  
    "0x412bf9D0937dAFc996e16A1F468E41fB074E072C":
    {
        name: 'AXL-USDC LP',
        img: '/images/logos/axl.png',
        img2: '/images/logos/usdc.png',
    },  
    "0x2077b8C5231CAfC0664841C28A1c531174d70DE1":
    {
        name: 'WAVAX-USDC LP',
        img: '/images/logos/avax.png',
        img2: '/images/logos/usdc.png',
    },  
    "0x1204B302942598157B89424aEd17080EDa5b27Ea":
    {
        name: 'WBNB-USDC LP',
        img: '/images/logos/bnb.png',
        img2: '/images/logos/usdc.png',
    },  
    "0x5Aa05950B1be4073581405b83330dB30B8260e33":
    {
        name: 'WMATIC-USDC LP',
        img: '/images/logos/matic.png',
        img2: '/images/logos/usdc.png',
    },
    "0x9B6Bd7D9b480249d11678DCc955A2152a0C2E879":
    {
        name: 'stOSMO-USDC LP',
        img: '/images/logos/stosmo.png',
        img2: '/images/logos/usdc.png',
    },  
    "0xc76E6c6031A2766B3b6e789241212Bc99468d822":
    {
        name: 'stATOM-USDC LP',
        img: '/images/logos/statom.png',
        img2: '/images/logos/usdc.png',
    },  
    "0xef988ddcb4089E4CA6d89EdEFbab1B11208B4e40":
    {
        name: 'CNTO-stATOM LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/statom.png',
    },  
    "0x7Ce91492d9D3c102E46a218Ee74bB093f63190C3":
    {
        name: 'CNTO-stOSMO LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/stosmo.png',
    },
    "0x4Cac79065cba792E25c0fEfa45810370A6f5b570":
    {
        name: 'CNTO-stkATOM LP',
        img: '/images/logos/ciento.png',
        img2: '/images/logos/stkatom.png',
    }, 
    "0x0e9B759e05Cdde29BaB3ba1745d469E8BaBA9fB3":
    {
        name: 'stkATOM-USDC LP',
        img: '/images/logos/stkatom.png',
        img2: '/images/logos/usdc.png',
    },                          
    "chainId": 9052,
    "decimals": 18,
    "explorer": "https://acrescout.mindheartsoul.org/",
    "network": "Mainnet",
}