export const months = {
    "0":
    {
        month: 'Jan',
    },
    "1":
    {
        month: 'Feb',
    },
    "2":
    {
        month: 'Mar',
    },
    "3":
    {
        month: 'April',
    },
    "4":
    {
        month: 'May',
    },
    "5":
    {
        month: 'June',
    },
    "6":
    {
        month: 'July',
    },
    "7":
    {
        month: 'Aug',
    },
    "8":
    {
        month: 'Sep',
    },
    "9":
    {
        month: 'Oct',
    },
    "10":
    {
        month: 'Nov',
    },
    "11":
    {
        month: 'Dec',
    },
        
}