import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { addresses } from '../components/Assets/addresses';
import farmABI from '../components/abi/farmContractABI.json'
import tokenABI from '../components/abi/tokenABI.json';
import { values } from '../components/Assets/values';

const My_Pools = () => { 

    function CommaFormatted(amount) {
        var delimiter = ","; // replace comma if desired
        var a = amount.split('.',2)
        var d = a[1];
        var i = parseInt(a[0]);
        if(isNaN(i)) { return ''; }
        var minus = '';
        if(i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while(n.length > 3) {
          var nn = n.substr(n.length-3);
          a.unshift(nn);
          n = n.substr(0,n.length-3);
        }
        if(n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if(d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + amount;
        return amount;
      }    

  function expo(x, f) {
    return Number.parseFloat(x).toExponential(f);
  }

  function counter(id, start, end, duration) {
    let obj = document.getElementById(id),
     current = start,
     range = end - start,
     increment = end > start ? 1 : -1,
     step = Math.abs(Math.floor(duration / range)),
     timer = setInterval(() => {
      current += increment;
      obj.textContent = current + '.000';
      if (current > end) {
       clearInterval(timer);
       obj.textContent = end;
      }
     }, step);
  }

  const live = true;

  const farmAddress = addresses.farm.address;
  console.log("Farm Address : ", farmAddress);

  const [rewardsMessage, setrewardsMessage] = useState('');
  const [chainError, setChainError] = useState(false);
  const [myTVL, setpmyTVL] = useState('');

  const [dataMain, setDataMain] = useState<any[]>([])

  const provider = new ethers.providers.Web3Provider(window.ethereum as any);
  const signer = provider.getSigner();
  const farmContract = new ethers.Contract(farmAddress, farmABI, signer);
  //const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
  //const tokenContract = useTokenContract(tokenAddress);

  useEffect(() => {

    const connectMetaMask = async () => {

        getAccount();

        let response = await fetch('https://testnet.ciento.exchange/prices');
        let data = await response.json();
        var acrePrice = data[0].price;
        var atomPrice = data[2].price;
        var osmoPrice = data[3].price;
        var axlPrice = data[6].price;
        var wbnbPrice = data[8].price;
        var wethPrice = data[9].price;
        var wbtcPrice = data[10].price;
        var wavaxPrice = data[11].price;
        var wmaticPrice = data[12].price;
        var cntoPrice = data[13].price;
        var statomPrice = data[16].price;
        var stosmoPrice = data[17].price;
        var stkatomPrice = data[18].price;
        var usdcPrice = 1;   
        
        var mytotalTVL = 0;

        var arrData: any[] = [];
  
        if(account) {

            var id = 0;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);

            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);

                var result = await tokenContract.getReserves();
                var acreTotal = (result[0].toString() / 1000000000000000000);
                var cntoTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (acreTotal * acrePrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var acreSingle = acreTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var acreUSD = (acreSingle * userLP) * acrePrice;
                var lpUSD = cntoUSD + acreUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var acreSingle = acreTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var acreUSD = (acreSingle * userLP) * acrePrice;
                var userlpUSD = cntoUSD + acreUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((acreSingle * userLP).toFixed(2)), "coin2name": 'ACRE' }); 
                
                mytotalTVL = mytotalTVL + userlpUSD;

            }



            var id = 6;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = cntoUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = cntoUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }



            var id = 1;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);

                var result = await tokenContract.getReserves();
                var atomTotal = (result[0].toString() / 1000000);
                var cntoTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (atomTotal * atomPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var atomSingle = atomTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var atomUSD = (atomSingle * userLP) * atomPrice;
                var lpUSD = cntoUSD + atomUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var atomSingle = atomTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var atomUSD = (atomSingle * userLP) * atomPrice;
                var userlpUSD = cntoUSD + atomUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((atomSingle * userLP).toFixed(2)), "coin2name": 'ATOM' }); 
                
                mytotalTVL = mytotalTVL + userlpUSD;

            }        
            
            

            var id = 2;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var osmoTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (osmoTotal * osmoPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var osmoSingle = osmoTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var osmoUSD = (osmoSingle * userLP) * osmoPrice;
                var lpUSD = cntoUSD + osmoUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var osmoSingle = osmoTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var osmoUSD = (osmoSingle * userLP) * osmoPrice;
                var userlpUSD = cntoUSD + osmoUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((osmoSingle * userLP).toFixed(2)), "coin2name": 'OSMO' }); 
                
                mytotalTVL = mytotalTVL + userlpUSD;

            }    
            
            

            var id = 3;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var wbnbTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wbnbTotal * wbnbPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wbnbSingle = wbnbTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
                var lpUSD = cntoUSD + wbnbUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wbnbSingle = wbnbTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
                var userlpUSD = cntoUSD + wbnbUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((wbnbSingle * userLP).toFixed(2)), "coin2name": 'WBNB' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }                

  

            var id = 4;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var wmaticTotal = (result[0].toString() / 1000000000000000000);
                var cntoTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wmaticTotal * wmaticPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wmaticSingle = wmaticTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
                var lpUSD = cntoUSD + wmaticUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wmaticSingle = wmaticTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
                var userlpUSD = cntoUSD + wmaticUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((wmaticSingle * userLP).toFixed(2)), "coin2name": 'WMATIC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }              
            

      

            var id = 5;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var wavaxTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wavaxTotal * wavaxPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wavaxSingle = wavaxTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
                var lpUSD = cntoUSD + wavaxUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wavaxSingle = wavaxTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
                var userlpUSD = cntoUSD + wavaxUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((wavaxSingle * userLP).toFixed(2)), "coin2name": 'WAVAX' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }                 



            var id = 20;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var stkatomTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (stkatomTotal * stkatomPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var stkatomSingle = stkatomTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var stkatomUSD = (stkatomSingle * userLP) * stkatomPrice;
                var lpUSD = cntoUSD + stkatomUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var stkatomSingle = stkatomTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var stkatomUSD = (stkatomSingle * userLP) * stkatomPrice;
                var userlpUSD = cntoUSD + stkatomUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((stkatomSingle * userLP).toFixed(2)), "coin2name": 'stkATOM' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }        
            
            


            var id = 18;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var statomTotal = (result[0].toString() / 1000000);
                var cntoTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (statomTotal * statomPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var statomSingle = statomTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var statomUSD = (statomSingle * userLP) * statomPrice;
                var lpUSD = cntoUSD + statomUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var statomSingle = statomTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var statomUSD = (statomSingle * userLP) * statomPrice;
                var userlpUSD = cntoUSD + statomUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((statomSingle * userLP).toFixed(2)), "coin2name": 'stATOM' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }         
            
            


            var id = 19;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);

                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var stosmoTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (stosmoTotal * stosmoPrice) + (cntoTotal * cntoPrice);

                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var stosmoSingle = stosmoTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var stosmoUSD = (stosmoSingle * userLP) * stosmoPrice;
                var lpUSD = cntoUSD + stosmoUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var stosmoSingle = stosmoTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var stosmoUSD = (stosmoSingle * userLP) * stosmoPrice;
                var userlpUSD = cntoUSD + stosmoUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((cntoSingle * userLP).toFixed(2)), "coin1name": 'CNTO', "coin2": CommaFormatted((stosmoSingle * userLP).toFixed(2)), "coin2name": 'stOSMO' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }      
            

            
            var id = 16;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var stosmoTotal = (result[0].toString() / 1000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (stosmoTotal * stosmoPrice);

                userLP = userLP / 1000000000000000000;
                var stosmoSingle = stosmoTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var stosmoUSD = (stosmoSingle * userLP) * stosmoPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = stosmoUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var stosmoSingle = stosmoTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var stosmoUSD = (stosmoSingle * userLP) * stosmoPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = stosmoUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((stosmoSingle * userLP).toFixed(2)), "coin1name": 'stOSMO', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }                  



            var id = 17;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var statomTotal = (result[0].toString() / 1000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (statomTotal * statomPrice);

                userLP = userLP / 1000000000000000000;
                var statomSingle = statomTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var statomUSD = (statomSingle * userLP) * statomPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = statomUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var statomSingle = statomTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var statomUSD = (statomSingle * userLP) * statomPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = statomUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((statomSingle * userLP).toFixed(2)), "coin1name": 'stATOM', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' }); 
                
                mytotalTVL = mytotalTVL + userlpUSD;

            }                    



            var id = 7;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var acreTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (acreTotal * acrePrice);

                userLP = userLP / 1000000000000000000;
                var acreSingle = acreTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var acreUSD = (acreSingle * userLP) * acrePrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = acreUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var acreSingle = acreTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var acreUSD = (acreSingle * userLP) * acrePrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = acreUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((acreSingle * userLP).toFixed(2)), "coin1name": 'ACRE', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }               




            var id = 8;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var wbtcTotal = (result[0].toString() / 100000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (wbtcTotal * wbtcPrice);

                userLP = userLP / 1000000000000000000;
                var wbtcSingle = wbtcTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wbtcUSD = (wbtcSingle * userLP) * wbtcPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = wbtcUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var wbtcSingle = wbtcTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wbtcUSD = (wbtcSingle * userLP) * wbtcPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = wbtcUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((wbtcSingle * userLP).toFixed(2)), "coin1name": 'WBTC', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }     
            
            

            var id = 9;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var wethTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (wethTotal * wethPrice);

                userLP = userLP / 1000000000000000000;
                var wethSingle = wethTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wethUSD = (wethSingle * userLP) * wethPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = wethUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var wethSingle = wethTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wethUSD = (wethSingle * userLP) * wethPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = wethUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((wethSingle * userLP).toFixed(2)), "coin1name": 'WETH', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }     



            var id = 10;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var atomTotal = (result[0].toString() / 1000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (atomTotal * atomPrice);

                userLP = userLP / 1000000000000000000;
                var atomSingle = atomTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var atomUSD = (atomSingle * userLP) * atomPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = atomUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var atomSingle = atomTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var atomUSD = (atomSingle * userLP) * atomPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = atomUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((atomSingle * userLP).toFixed(2)), "coin1name": 'ATOM', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }    



            var id = 11;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var osmoTotal = (result[0].toString() / 1000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (osmoTotal * osmoPrice);

                userLP = userLP / 1000000000000000000;
                var osmoSingle = osmoTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var osmoUSD = (osmoSingle * userLP) * osmoPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = osmoUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var osmoSingle = osmoTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var osmoUSD = (osmoSingle * userLP) * osmoPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = osmoUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((osmoSingle * userLP).toFixed(2)), "coin1name": 'OSMO', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }                




            var id = 12;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var axlTotal = (result[0].toString() / 1000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (axlTotal * axlPrice);

                userLP = userLP / 1000000000000000000;
                var axlSingle = axlTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var axlUSD = (axlSingle * userLP) * axlPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = axlUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var axlSingle = axlTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var axlUSD = (axlSingle * userLP) * axlPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = axlUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((axlSingle * userLP).toFixed(2)), "coin1name": 'AXL', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }                



            var id = 13;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var wavaxTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (wavaxTotal * wavaxPrice);

                userLP = userLP / 1000000000000000000;
                var wavaxSingle = wavaxTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = wavaxUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var wavaxSingle = wavaxTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = wavaxUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((wavaxSingle * userLP).toFixed(2)), "coin1name": 'WAVAX', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }                




            var id = 14;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var usdcTotal = (result[0].toString() / 1000000);
                var wbnbTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (wbnbTotal * wbnbPrice);

                userLP = userLP / 1000000000000000000;
                var wbnbSingle = wbnbTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = wbnbUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var wbnbSingle = wbnbTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = wbnbUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((wbnbSingle * userLP).toFixed(2)), "coin1name": 'WBNB', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }   



            var id = 15;
            var taddress = addresses[id].address;
            var tokenContract = new ethers.Contract(taddress, tokenABI, signer);
            var userLP = await farmContract.getDepositAmount(account, id);

            if(userLP > 0)
            {

                var userLP = await tokenContract.balanceOf(farmAddress);
                
                var result = await tokenContract.getReserves();
                var wmaticTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (usdcTotal * usdcPrice) + (wmaticTotal * wmaticPrice);

                userLP = userLP / 1000000000000000000;
                var wmaticSingle = wmaticTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = wmaticUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var wmaticSingle = wmaticTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = wmaticUSD + usdcUSD;
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
      
        
                var allocPoint = await farmContract.poolInfo(id);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));

                arrData.push({"id": id, "taddress": taddress, "apr": CommaFormatted(myAPR.toFixed(2)), "usd": CommaFormatted(userlpUSD.toFixed(2)), "coin1": CommaFormatted((wmaticSingle * userLP).toFixed(2)), "coin1name": 'WMATIC', "coin2": CommaFormatted((usdcSingle * userLP).toFixed(2)), "coin2name": 'USDC' });  

                mytotalTVL = mytotalTVL + userlpUSD;

            }

            setpmyTVL(CommaFormatted(mytotalTVL.toFixed(2)));




            
        }

        setDataMain(arrData);

    }
      
    connectMetaMask()
      .catch(console.error);       

  }, []);  


  const handleURL = async (e) => {
    console.log("Testing one", e.target.value);
    const url = '/#/farm/' + e.target.value;
    window.location.assign(url);
  };


  const getAccount = async () => {
    
  }    
  

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)


  return (
    <>
      <div className='farmsClass'>
      <AppBody>
        <Wrapper id="farms-page">
          

              <div className='buttons center'>   

                <div className='myHeader'>
                  <img src="/images/Ciento-Main.png" />
                  <p className='myTitle'>Ciento Exchange - My Pools</p>
                  
                  { rewardsMessage ? <p className='mySub'>{ rewardsMessage }</p> : '' }

                </div>
        
                { account ? "" :
                  <div className='myUnlockWallet'>
                    <ConnectWalletButton width="100%" />
                  </div>
                }
                
                { chainError === true ?
                  <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                  </div>
                : "" } 
        
              </div>

              { account && live ?
        <div className='blockMain'>
   

   {dataMain.length === 0 ? <div className='myLoading'>Scanning all Pools ... <span className="myloaderwhite"></span> ... This can take 30 seconds!! Please Wait!!</div> : ""}

        {dataMain.length > 0 ?        
        <div className='myAPR'>
          <p>My TVL: ${myTVL ? myTVL : <span className="myloaderwhite"></span>}
          </p>
        </div> 
        : ""}

                {dataMain.map((item, index) => (
                <div key={index} className='blocks myblock'>
                    <img className='img_left' src={values[item.taddress].img} />
                    <img className='img_right' src={values[item.taddress].img2} />
                    <p className='poolTitle'>{values[item.taddress].name}</p>
                    <p className='poolTextTwo'><b>LP Value:</b> ${item.usd} <br></br>
                    <b>{item.coin1name}:</b> {item.coin1} <br></br>
                    <b>{item.coin2name}:</b> {item.coin2} </p>
                    { account ? 
                      <button value={item.id} onClick={handleURL}>Select</button>
                    : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{item.apr} %</p>
                    </div>
                </div> 
                ))}                 


        </div>
    : <div></div> }

  



        </Wrapper>
      </AppBody>
      </div>
    </>
  )
}

export default My_Pools