import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { addresses } from '../components/Assets/addresses';
import { values } from '../components/Assets/values';
import { months } from '../components/Assets/months';
import lockerABI from '../components/abi/lockerContractABI.json'

const Road_Map = () => { 

    const lockerAddress = addresses.locker.address;

    const [chainID, setChainID] = useState();
    const [address, setAddress] = useState('');
    const [myCount, setMyCount] = useState();
    const [lockID, setLockID] = useState();
    const [executeVal, setExecuteVal] = useState('');
    const [execute, setExecute] = useState(false);
    const [txValue, setTxValue] = useState('');
    const [success, setSuccess] = useState(false);
    const [chainError, setChainError] = useState(false);
    const [dataMain, setDataMain] = useState<any[]>([])
    const [valerror, setError] = useState('');
    const [activeButton, setActiveButton] = useState("");  

    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const signer = provider.getSigner();
    const lockerContract = new ethers.Contract(lockerAddress, lockerABI, signer);
  
    useEffect(() => {   
  
    }, []); 
  
    const theme = useContext(ThemeContext)
  
  
    return (
      <>
        <div className='farmsClass'>
        <AppBody>
          <Wrapper id="farms-page">
        

              <div className='blockMain'>

                <img className="roadmap" src="/images/roadmap.png" />

              </div>
  
  
  
          </Wrapper>
        </AppBody>
        </div>
      </>
    )
  };
  
  export default Road_Map
  