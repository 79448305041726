import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { addresses } from '../components/Assets/addresses';
import farmABI from '../components/abi/farmContractABI.json'
import tokenABI from '../components/abi/tokenABI.json';
import { values } from '../components/Assets/values';

const Stake = () => { 


    function counter(id, start, end, duration) {
      let obj = document.getElementById(id),
       current = start,
       range = end - start,
       increment = end > start ? 1 : -1,
       step = Math.abs(Math.floor(duration / range)),
       timer = setInterval(() => {
        current += increment;
        obj.textContent = current + '.000';
        if (current > end) {
         clearInterval(timer);
         obj.textContent = end;
        }
       }, step);
    }
  
    const id = 0;

    const live = true;
  
    const farmAddress = addresses.stake.address;
    const tokenAddress = addresses.stake_token.address;
    const [chainID, setChainID] = useState();
    const [address, setAddress] = useState('');
    const [tokenbalance, setTokenBalance] = useState('');
    const [stakeInputVal, setStakeInputVal] = useState('');
    const [unstakeInputVal, setUnstakeInputVal] = useState('');
    const [rewardsbalance, setRewardsBalance] = useState('');
    const [stakedbalance, setStakedBalance] = useState('');
    const [stakeOnValue, setStakeOnValue] = useState('');
    const [unstakeOnValue, setUnstakeOnValue] = useState('');
    const [allowance, setAllowance] = useState('');
    const [allowanceFlag, setAllowanceFlag] = useState(false);
    const [approve, setApprove] = useState(false);
    const [spinner, setSpinnerVal] = useState('');
    const [spinner_claim, setSpinnerClaimVal] = useState('');
    const [executeVal, setExecuteVal] = useState('');
    const [execute, setExecute] = useState(false);
    const [txValue, setTxValue] = useState('');
    const [success, setSuccess] = useState(false);
    const [chainError, setChainError] = useState(false);
    const [claimSuccess, setClaimSuccess] = useState(false);    
    const [valerror, setError] = useState('');
    const [pOneAPR, setpOneAPR] = useState('');
  
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const signer = provider.getSigner();
    const farmContract = new ethers.Contract(farmAddress, farmABI, signer);
    const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
    //const tokenContract = useTokenContract(tokenAddress);
  
    useEffect(() => {
  
      const connectMetaMask = async () => {
          const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
          console.log("MyAddress = ", account);
          console.log("signer = ", signer);
          var balance = await tokenContract.balanceOf(account);
          console.log("Balance 1 = ", balance.toString());        
          getAccount();

          let response = await fetch('https://testnet.ciento.exchange/prices');
          let data = await response.json();
          var cntoPrice = data[13].price;

        ///////////// Pool 1 ////////////////////

        var result = await tokenContract.balanceOf(farmAddress)
        var cntoTotal = (result.toString() / 1000000000000000000);
        //cntoTotal = 42510;
        var lpUSD = cntoTotal * cntoPrice;
        var perday = 400 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        var totalAllocPoint = await farmContract.totalAllocPoint();
        console.log("TotalAllocPoint = ", totalAllocPoint.toString());
        totalAllocPoint = totalAllocPoint.toString();

        var allocPoint = await farmContract.poolInfo(0);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpOneAPR(myAPR.toFixed(2));

      }
        
      connectMetaMask()
        .catch(console.error);       
  
    }, []);  
  
    const getAccount = async () => {
        //const balance = await provider.getBalance(signer.getAddress());
        var balance = await tokenContract.balanceOf(signer.getAddress());
        const address = await signer.getAddress();
        setAddress(address);
        setTokenBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(3));

        balance = await farmContract.getDepositAmount(signer.getAddress(), id);
        setStakedBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(3));

        var mySBalance = parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(3);
        
        balance = await farmContract.pendingToken(id, signer.getAddress());
        setRewardsBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(3));
        
        var myRBalance = parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(3);
      
        if(+myRBalance > 0)
          counter("rBalance", (+myRBalance - 20), myRBalance, 800);

        if(+mySBalance > 0)          
          counter("sBalance", (+mySBalance - 20), mySBalance, 800);
      }   

      const getAllowance = async () => {
        const allowance = await tokenContract.allowance(signer.getAddress(), farmAddress);
        setAllowance(allowance.toString());
        if(allowance.gt(ethers.utils.parseUnits(stakeInputVal, values['decimals'])) || allowance.eq(ethers.utils.parseUnits(stakeInputVal, values['decimals'])))
        {
          setAllowanceFlag(true);
          setSpinnerVal('');
          setApprove(false);          
        }
      }        
      
      
      const stakeOn = () => {
        console.log("Inside");
        if(stakeOnValue === '')
        {
          setStakeOnValue('1');
          setUnstakeOnValue('');
          setClaimSuccess(false);
          setSuccess(false);
        }
        else
        {
          setStakeOnValue('');
          setUnstakeOnValue('');
          setClaimSuccess(false);
          setSuccess(false);
        }
      }
      
      const unstakeOn = () => {
        if(unstakeOnValue === '')
        {
          setUnstakeOnValue('1');
          setStakeOnValue('');
          setClaimSuccess(false);
          setSuccess(false);
        }
        else
        {
          setStakeOnValue('');
          setUnstakeOnValue('');
          setClaimSuccess(false);
          setSuccess(false);
        }
      }
      
      
      const handleStakeChange = async (e) => {
        e.preventDefault();
        setError('');
        setStakeInputVal(e.target.value);

        setAllowanceFlag(false);
        console.log("Inside Get Allowance = ", e.target.value);
        const allowance = await tokenContract.allowance(signer.getAddress(), farmAddress);
        console.log("inside 2");
        setAllowance(allowance.toString());
        if(allowance.gt(ethers.utils.parseUnits(e.target.value, values['decimals'])) || allowance.eq(ethers.utils.parseUnits(e.target.value, values['decimals'])))
        {
          setAllowanceFlag(true);      
        }        

      }


      const handleUnstakeChange = async (e) => {
        e.preventDefault();
        setError('');
        setUnstakeInputVal(e.target.value);
      }      

    
      const handleStakeMax = async (e) => {
        e.preventDefault();
        setError('');
        setStakeInputVal(tokenbalance);

        setAllowanceFlag(false);
        const allowance = await tokenContract.allowance(signer.getAddress(), farmAddress);
        setAllowance(allowance.toString());
        if(allowance.gt(ethers.utils.parseUnits(tokenbalance, values['decimals'])) || allowance.eq(ethers.utils.parseUnits(tokenbalance, values['decimals'])))
        {
          setAllowanceFlag(true);
        }         
      } 


      const handleUnstakeMax = async (e) => {
        e.preventDefault();
        setError('');
        setUnstakeInputVal(stakedbalance);
      }       
      

      const handleApprove = async (e) => {
        e.preventDefault();
        setError('');
        setStakeInputVal(e.target.value);
        console.log("stakeInputVal = ", stakeInputVal);
        if(!stakeInputVal)
        {
          setError('Please enter correct value.');
        }
        else if(stakeInputVal.length === 0 || +stakeInputVal === 0)
        {
          setError('Please enter correct value.');
        }
        else if(ethers.utils.parseUnits(stakeInputVal, values['decimals']).gt(ethers.utils.parseUnits(tokenbalance, values['decimals'])))
        {
          setError('Not enough balance.');
        }
        else
        {
          try {
            setError(''); 
            setSpinnerVal('spinner-border spinner-border-sm mr-1');
            setApprove(true); 
            const tokenVal = ethers.utils.parseUnits(stakeInputVal, values['decimals']);
            const result = await tokenContract.approve(farmAddress, tokenVal);
            await result.wait();
            setAllowance(stakeInputVal);
            getAllowance();
            setStakeInputVal(stakeInputVal);
          } catch (error) {
            console.error(error);
          }            
        }        
      }



      const handleClaim = async (e) => {
        e.preventDefault();
        setError('');
        if(!rewardsbalance)
        {
          setError('Rewards Empty.');
        }
        else if(rewardsbalance.length === 0 || +rewardsbalance === 0)
        {
          setError('Rewards Empty.');
        }
        else
        {  
          setError(''); 
          setSpinnerClaimVal('spinner-border spinner-border-sm mr-1');
          setApprove(true); 

          try {
            setStakeOnValue('');
            setUnstakeOnValue('');
            console.log("ID = ", id);
            const result = await farmContract.claim(id);
            await result.wait();
            setClaimSuccess(true);
            setTxValue(values['explorer'] + "tx/" + result.hash);
            setApprove(false);
            setSpinnerClaimVal('');
            getAccount();
          } catch (error) {
            console.error(error);
          }


        }        
      }      
      

      const handleStake = async (e) => {
        e.preventDefault();

        console.log("stakeValue = ", stakeInputVal);
        
        if(!stakeInputVal)
        {
          setError('Please enter correct value.');
        }
        else if(+stakeInputVal == 0)
        {
          setError('Please enter correct value.');
        }        
        else if(stakeInputVal.length === 0 || +stakeInputVal === 0)
        {
          setError('Please enter correct value.');
        }
        else if(ethers.utils.parseUnits(stakeInputVal, values['decimals']).gt(ethers.utils.parseUnits(tokenbalance, values['decimals'])))
        {
          setError('Not enough balance.');
        }
        else
        {
          setExecute(true);
          setError('');
          setExecuteVal('spinner-border spinner-border-sm mr-1');

          try {
            var tokenVal = ethers.utils.parseUnits(stakeInputVal, values['decimals']);
            const total = await tokenContract.balanceOf(account);
            if(total.lt(tokenVal))
              tokenVal = total; 
            const result = await farmContract.deposit(id, tokenVal, false);
            await result.wait();
            setSuccess(true);
            setTxValue(values['explorer'] + "tx/" + result.hash);
            setExecute(false);
            setExecuteVal('');
            getAccount();
          } catch (error) {
            console.error(error);
          }
    
        }
      }



      const handleUnstake = async (e) => {
        e.preventDefault();
        
        if(!unstakeInputVal)
        {
          setError('Please enter correct value.');
        }
        else if(+unstakeInputVal == 0)
        {
          setError('Please enter correct value.');
        }        
        else if(unstakeInputVal.length === 0 || +unstakeInputVal === 0)
        {
          setError('Please enter correct value.');
        }
        else if(ethers.utils.parseUnits(unstakeInputVal, values['decimals']).gt(ethers.utils.parseUnits(stakedbalance, values['decimals'])))
        {
          setError('Not enough balance.');
        }
        else
        {
          setExecute(true);
          setError('');
          setExecuteVal('spinner-border spinner-border-sm mr-1');

          try {
            var tokenVal = ethers.utils.parseUnits(unstakeInputVal, values['decimals']);
            const total = await farmContract.getDepositAmount(account, id);
            console.log("Totalllll = ", total.toString());
            if(total.lt(tokenVal))
              tokenVal = total; 
            const result = await farmContract.requestWithdraw(id, tokenVal, false);
            await result.wait();
            setSuccess(true);
            setTxValue(values['explorer'] + "tx/" + result.hash);
            setExecute(false);
            setExecuteVal('');
            getAccount();
          } catch (error) {
            console.error(error);
          }
    
        }
    }      
  
    const { account } = useActiveWeb3React()
    const theme = useContext(ThemeContext)
  
  
    return (
      <>
        <div className='farmsClass'>
        <AppBody>
          <Wrapper id="farms-page">
  
  
  
  
                <div className='buttons center'>   
  
                  <div className='myHeader'>
                    <img src="/images/Ciento-Main.png" />
                    <p className='myTitle'>Ciento Exchange</p>
                    <p className='mySub'>Swap Tokens, earn CNTO Rewards for Liquidty Mining and Staking!</p>
                    <p className='mySub'>NOTE: Please unstake from old staking & stake CNTO over here. <a href="https://ciento.exchange/#/stake-old"><u>Click Here</u></a> to go to Old Staking page.</p>
                    <p className='mySub'>CNTO Rewards will start from 22nd April 2023.</p>                    
                  </div>
          
                  { account ? "" :
                    <div className='myUnlockWallet'>
                      <ConnectWalletButton width="100%" />
                    </div>
                  }
                  
                  { chainError === true ?
                    <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                    </div>
                  : "" } 
          
                </div>
  
                { account && live ?

      <div className='blockMain'>
        
        <div className='myAPR'>
          <p>APR: {pOneAPR ? pOneAPR : "NA"} %</p>       
        </div>

        <div className='blocks myblock'>
          <img src="https://backups.synergynodes.com/images/logos/ciento.png" />
          <p className='rewardsBalance'><p id='rBalance'>{rewardsbalance}</p><span>CNTO Earned</span></p>
          <div className='farmButtons'>

            {+rewardsbalance > 0 ? <button type="button" disabled={approve ? true : false } onClick={handleClaim} className="btn btn-primary claim"> <span className={spinner}></span> Claim Rewards</button> : <button type="button" disabled className="btn btn-primary claim"> Claim Rewards</button>
            }
            
          </div>
        </div>   

        <div className='blocks myblock'>
          <img src={values[tokenAddress].img} />
          <p className='rewardsBalance'><p id='sBalance'>{stakedbalance}</p> <span>{values[tokenAddress].name} Staked</span></p>
            <div className='farmButtons'>
              <button className='left' onClick={stakeOn}>Stake</button>
              <button className='right' onClick={unstakeOn}>Unstake</button>
            </div>

        </div>  

        <br></br>

        { claimSuccess ? 
        <div className='myStake'>
          <div className='success'>
            <p className='txHead'>Success!</p>
            <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
          </div>
        </div>
        : "" }

        
        
        { stakeOnValue ?
        <form onSubmit={handleStake} >  
        
        <div className='myStake'>
          <p className='stakeTitle'>Stake {values[tokenAddress].name} Tokens
            <span>Available: {tokenbalance}</span>
          </p>
          <div className='stakeInput'>
            <span className="spanInput"><input type="number" className='inputClass' onChange={handleStakeChange} placeholder="0" value={stakeInputVal} /></span>
            <span className="spanInput">{values[tokenAddress].name}</span>
            <span className="spanInput"><a href="#" onClick={handleStakeMax}>MAX</a></span>
          </div>
          <div>{valerror ? <span className='warning'>{valerror}</span> : '' }</div>
          

          <div className='farmButtons'>
          {(allowanceFlag ? <button type="submit" disabled={execute ? true : false } className="btn btn-primary confirm"><span className={executeVal}></span> Submit</button>: <button type="button" disabled={approve ? true : false } onClick={handleApprove} className="btn btn-primary blue"> <span className={spinner}></span> Approve</button>)}
          </div>        

          { success ? 
            <div className='success'>
              <p className='txHead'>Success!</p>
              <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
            </div>
    
            : "" }           

        </div>   

        </form>  

        : "" }





        { unstakeOnValue ?
          <form onSubmit={handleUnstake} >  
          
          <div className='myStake'>
            <p className='stakeTitle'>Withdraw {values[tokenAddress].name} Tokens
              <span>Staked: {stakedbalance}</span>
            </p>
            <div className='stakeInput'>
              <span className="spanInput"><input type="number" className='inputClass' onChange={handleUnstakeChange} placeholder="0" value={unstakeInputVal} /></span>
              <span className="spanInput">{values[tokenAddress].name}</span>
              <span className="spanInput"><a href="#" onClick={handleUnstakeMax}>MAX</a></span>
            </div>
            <div>{valerror ? <span className='warning'>{valerror}</span> : '' }</div>
            
    
            <div className='farmButtons'>
              <button type="submit" disabled={execute ? true : false } className="btn btn-primary confirm"><span className={executeVal}></span> Confirm</button>
            </div>  
            
            <span className='warning'>NOTE: Tokens will be locked for 1 day.</span>          
    
            { success ? 
              <div className='success'>
                <p className='txHead'>Success!</p>
                <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
              </div>
      
              : "" }           
    
          </div>   
    
          </form>  
    
          : "" }      



      </div>
    : <div></div> }
  
  
  
  <span className='warningTwo'>NOTE: Unbonding period is 1 day.</span> 
  
  
          </Wrapper>
        </AppBody>
        </div>
      </>
    )
  }
  
  export default Stake
  