import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { addresses } from '../components/Assets/addresses';
import farmABI from '../components/abi/farmContractABI.json'
import tokenABI from '../components/abi/tokenABI.json';
import { values } from '../components/Assets/values';

const Farms = () => {

    const contractAddress = "";
    const [chainID, setChainID] = useState();
    const [address, setAddress] = useState('');
    const [accbalance, setBalance] = useState();
    const [chainError, setChainError] = useState(false);
  
  
    const getURLOne = () => {
      window.location.assign('/#/farms_cnto');
    }
    
    const getURLTwo = () => {
      window.location.assign('/#/farms_usdc');
    }
  
    const { account } = useActiveWeb3React()
    const theme = useContext(ThemeContext)
  
  

  return (
    <>
      <div className='farmsClass'>
      <AppBody>
        <Wrapper id="farms-page">




              <div className='buttons center'>   

                <div className='myHeader'>
                  <img src="/images/Ciento-Main.png" />
                  <p className='myTitle'>Ciento Exchange</p>
                  <p className='mySub'>Swap Tokens, earn CNTO Rewards for Liquidty Mining and Staking!</p>
                  <p className='mySub'>CNTO Rewards for all New Pools will start from 18th March 2023.</p>  
                </div>
        
                { account ? "" :
                  <div className='myUnlockWallet'>
                    <ConnectWalletButton width="100%" />
                  </div>
                }
                
                { chainError === true ?
                  <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                  </div>
                : "" } 
        
              </div>





<div className='blockMain'>
<div className='homeBlocks'>
    <div className='inside'>
        <img src="/images/logos/ciento.png" />
        <p className='poolTitle'>CNTO Farms</p>
        <p className='poolText'>Depost LP tokens & Earn CNTO as Rewards!</p>
        <button onClick={getURLOne}>Enter CNTO Farms</button>
    </div>                         
</div>
<div className='homeBlocks'>
    <div className='inside'>
        <img src="/images/logos/usdc.png" />
        <p className='poolTitle'>USDC Farms</p>
        <p className='poolText'>Depost LP tokens & Earn CNTO as Rewards!</p>
        <button onClick={getURLTwo}>Enter USDC Farms</button>
    </div>                        
</div>                           
</div>







        </Wrapper>
      </AppBody>
      </div>
    </>
  )
}

export default Farms
