export const config = [
    {
        id: "acrechain", values: 
        {
            "chainId": "acre_9052-1",
            "chainName": "Acrechain Mainnet",
            "rpc": "https://rpc.cosmos.directory/acrechain",
            "rest": "https://rest.cosmos.directory/acrechain",
            "bip44": {
              "coinType": 60
            },
            "coinType": 60,
            "bech32Config": {
              "bech32PrefixAccAddr": "acre",
              "bech32PrefixAccPub": "acrepub",
              "bech32PrefixValAddr": "acrevaloper",
              "bech32PrefixValPub": "acrevaloperpub",
              "bech32PrefixConsAddr": "acrevalcons",
              "bech32PrefixConsPub": "acrevalconspub"
            },
            "currencies": [
              {
                "coinDenom": "ACRE",
                "coinMinimalDenom": "aacre",
                "coinDecimals": 18,
                "coinGeckoId": "arable-protocol"
              },
              {
                "coinDenom": "arUSD",
                "coinMinimalDenom": "erc20/0x2Cbea61fdfDFA520Ee99700F104D5b75ADf50B0c",
                "coinDecimals": 18,
                "coinGeckoId": "pool:erc20/0x2Cbea61fdfDFA520Ee99700F104D5b75ADf50B0c",
                "coinImageUrl": "https://frontier.osmosis.zone/tokens/arusd.png"
              },
              {
                "coinDenom": "CNTO",
                "coinMinimalDenom": "erc20/0xAE6D3334989a22A65228732446731438672418F2",
                "coinDecimals": 18,
                "coinGeckoId": "pool:erc20/0xAE6D3334989a22A65228732446731438672418F2",
                "coinImageUrl": "https://testnet.ciento.exchange/images/Ciento-Main.png"
              }
            ],
            "gasPriceStep": {
              "low": 0,
              "average": 25000000000,
              "high": 40000000000
            },
            "features": [
              "ibc-transfer",
              "ibc-go",
              "eth-address-gen",
              "eth-key-sign"
            ],
            "stakeCurrency": {
              "coinDenom": "ACRE",
              "coinMinimalDenom": "aacre",
              "coinDecimals": 18,
              "coinGeckoId": "pool:aacre",
              "coinImageUrl": "https://frontier.osmosis.zone/tokens/acre.svg"
            },
            "feeCurrencies": [
              {
                "coinDenom": "ACRE",
                "coinMinimalDenom": "aacre",
                "coinDecimals": 18,
                "coinGeckoId": "pool:aacre",
                "coinImageUrl": "https://frontier.osmosis.zone/tokens/acre.svg",
                "gasPriceStep": {
                  "low": 10000000000,
                  "average": 25000000000,
                  "high": 40000000000
                }
              }
            ],
            "beta": true
        }
    },
    {
        id: "kujira", values: 
        {
            "chainId": "kaiyo-1",
            "chainName": "Kujira",
            "rpc": "https://kujira-rpc.polkachu.com",
            "rest": "https://rest.cosmos.directory/kujira",
            "bip44": {
              "coinType": 118
            },
            "bech32Config": {
              "bech32PrefixAccAddr": "kujira",
              "bech32PrefixAccPub": "kujirapub",
              "bech32PrefixValAddr": "kujiravaloper",
              "bech32PrefixValPub": "kujiravaloperpub",
              "bech32PrefixConsAddr": "kujiravalcons",
              "bech32PrefixConsPub": "kujiravalconspub"
            },
            "currencies": [
              {
                "coinDenom": "KUJI",
                "coinMinimalDenom": "ukuji",
                "coinDecimals": 6,
                "coinGeckoId": "kujira"
              },
              {
                "coinDenom": "USK",
                "coinMinimalDenom": "factory/kujira1qk00h5atutpsv900x202pxx42npjr9thg58dnqpa72f2p7m2luase444a7/uusk",
                "coinDecimals": 6,
                "coinGeckoId": "usk"
              },
              {
                "coinDenom": "CNTO",
                "coinMinimalDenom": "ibc/BF603AD88AA4C36FC8CA99F6D703CA1D7D437A1EA97023A73A8BA4437A05ABDF",
                "coinDecimals": 18,
                "coinGeckoId": "cnto"
              },              
              {
                "coinDenom": "axlUSDC",
                "coinMinimalDenom": "ibc/295548A78785A1007F232DE286149A6FF512F180AF5657780FC89C009E2C348F",
                "coinDecimals": 6,
                "coinGeckoId": "usdc"
              },
              {
                "coinDenom": "ATOM",
                "coinMinimalDenom": "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2",
                "coinDecimals": 6,
                "coinGeckoId": "cosmoshub"
              },
              {
                "coinDenom": "CMDX",
                "coinMinimalDenom": "ibc/3607EB5B5E64DD1C0E12E07F077FF470D5BC4706AFCBC98FE1BA960E5AE4CE07",
                "coinDecimals": 6,
                "coinGeckoId": "comdex"
              },
              {
                "coinDenom": "EVMOS",
                "coinMinimalDenom": "ibc/F3AA7EF362EC5E791FE78A0F4CCC69FEE1F9A7485EB1A8CAB3F6601C00522F10",
                "coinDecimals": 18,
                "coinGeckoId": "evmos"
              },
              {
                "coinDenom": "JUNO",
                "coinMinimalDenom": "ibc/EFF323CC632EC4F747C61BCE238A758EFDB7699C3226565F7C20DA06509D59A5",
                "coinDecimals": 6,
                "coinGeckoId": "juno"
              },
              {
                "coinDenom": "OSMO",
                "coinMinimalDenom": "ibc/47BD209179859CDE4A2806763D7189B6E6FE13A17880FE2B42DE1E6C1E329E23",
                "coinDecimals": 6,
                "coinGeckoId": "osmosis"
              },
              {
                "coinDenom": "SCRT",
                "coinMinimalDenom": "ibc/A358D7F19237777AF6D8AD0E0F53268F8B18AE8A53ED318095C14D6D7F3B2DB5",
                "coinDecimals": 6,
                "coinGeckoId": "secret"
              },
              {
                "coinDenom": "STARS",
                "coinMinimalDenom": "ibc/4F393C3FCA4190C0A6756CE7F6D897D5D1BE57D6CCB80D0BC87393566A7B6602",
                "coinDecimals": 6,
                "coinGeckoId": "stars"
              },
              {
                "coinDenom": "wAVAX",
                "coinMinimalDenom": "ibc/004EBF085BBED1029326D56BE8A2E67C08CECE670A94AC1947DF413EF5130EB2",
                "coinDecimals": 18,
                "coinGeckoId": "avalanche-2"
              },
              {
                "coinDenom": "wETH",
                "coinMinimalDenom": "ibc/1B38805B1C75352B28169284F96DF56BDEBD9E8FAC005BDCC8CF0378C82AA8E7",
                "coinDecimals": 18,
                "coinGeckoId": "ethereum"
              }
            ],
            "feeCurrencies": [
              {
                "coinDenom": "KUJI",
                "coinMinimalDenom": "ukuji",
                "coinDecimals": 6,
                "coinGeckoId": "kujira"
              },
              {
                "coinDenom": "USK",
                "coinMinimalDenom": "factory/kujira1qk00h5atutpsv900x202pxx42npjr9thg58dnqpa72f2p7m2luase444a7/uusk",
                "coinDecimals": 6,
                "coinGeckoId": "usk",
                "gasPriceStep": {
                  "low": 0.0015,
                  "average": 0.0025,
                  "high": 0.0035
                }
              },
              {
                "coinDenom": "axlUSDC",
                "coinMinimalDenom": "ibc/295548A78785A1007F232DE286149A6FF512F180AF5657780FC89C009E2C348F",
                "coinDecimals": 6,
                "coinGeckoId": "usdc",
                "gasPriceStep": {
                  "low": 0.0015,
                  "average": 0.0025,
                  "high": 0.0035
                }
              },
              {
                "coinDenom": "ATOM",
                "coinMinimalDenom": "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2",
                "coinDecimals": 6,
                "coinGeckoId": "cosmoshub",
                "gasPriceStep": {
                  "low": 0.000125,
                  "average": 0.000225,
                  "high": 0.000325
                }
              },
              {
                "coinDenom": "CMDX",
                "coinMinimalDenom": "ibc/3607EB5B5E64DD1C0E12E07F077FF470D5BC4706AFCBC98FE1BA960E5AE4CE07",
                "coinDecimals": 6,
                "coinGeckoId": "comdex",
                "gasPriceStep": {
                  "low": 0.00652,
                  "average": 0.00852,
                  "high": 0.01052
                }
              },
              {
                "coinDenom": "EVMOS",
                "coinMinimalDenom": "ibc/F3AA7EF362EC5E791FE78A0F4CCC69FEE1F9A7485EB1A8CAB3F6601C00522F10",
                "coinDecimals": 18,
                "coinGeckoId": "evmos",
                "gasPriceStep": {
                  "low": 617283951,
                  "average": 717283951,
                  "high": 817283951
                }
              },
              {
                "coinDenom": "JUNO",
                "coinMinimalDenom": "ibc/EFF323CC632EC4F747C61BCE238A758EFDB7699C3226565F7C20DA06509D59A5",
                "coinDecimals": 6,
                "coinGeckoId": "juno",
                "gasPriceStep": {
                  "low": 0.000288,
                  "average": 0.000388,
                  "high": 0.000488
                }
              },
              {
                "coinDenom": "OSMO",
                "coinMinimalDenom": "ibc/47BD209179859CDE4A2806763D7189B6E6FE13A17880FE2B42DE1E6C1E329E23",
                "coinDecimals": 6,
                "coinGeckoId": "osmosis",
                "gasPriceStep": {
                  "low": 0.00126,
                  "average": 0.0025,
                  "high": 0.00375
                }
              },
              {
                "coinDenom": "SCRT",
                "coinMinimalDenom": "ibc/A358D7F19237777AF6D8AD0E0F53268F8B18AE8A53ED318095C14D6D7F3B2DB5",
                "coinDecimals": 6,
                "coinGeckoId": "secret",
                "gasPriceStep": {
                  "low": 0.00137,
                  "average": 0.00237,
                  "high": 0.00337
                }
              },
              {
                "coinDenom": "STARS",
                "coinMinimalDenom": "ibc/4F393C3FCA4190C0A6756CE7F6D897D5D1BE57D6CCB80D0BC87393566A7B6602",
                "coinDecimals": 6,
                "coinGeckoId": "stars",
                "gasPriceStep": {
                  "low": 0.0488,
                  "average": 0.0588,
                  "high": 0.0688
                }
              },
              {
                "coinDenom": "wAVAX",
                "coinMinimalDenom": "ibc/004EBF085BBED1029326D56BE8A2E67C08CECE670A94AC1947DF413EF5130EB2",
                "coinDecimals": 18,
                "coinGeckoId": "avalanche-2",
                "gasPriceStep": {
                  "low": 78492936,
                  "average": 88492936,
                  "high": 98492936
                }
              },
              {
                "coinDenom": "wETH",
                "coinMinimalDenom": "ibc/1B38805B1C75352B28169284F96DF56BDEBD9E8FAC005BDCC8CF0378C82AA8E7",
                "coinDecimals": 18,
                "coinGeckoId": "ethereum",
                "gasPriceStep": {
                  "low": 964351,
                  "average": 1064351,
                  "high": 1164351
                }
              }
            ],
            "stakeCurrency": {
              "coinDenom": "KUJI",
              "coinMinimalDenom": "ukuji",
              "coinDecimals": 6,
              "coinGeckoId": "kujira"
            },
            "coinType": 118,
            "beta": true
          }
    },
    {
        id: "crescent", values: 
        {
            "chainId": "crescent-1",
            "chainName": "Crescent",
            "rpc": "https://crescent-rpc.polkachu.com",
            "rest": "https://crescent-api.polkachu.com",
            "bip44": {
                "coinType": "118"
            },
            "coinType": "118",
            "bech32Config": {
                "bech32PrefixAccAddr": "cre",
                "bech32PrefixAccPub": "crepub",
                "bech32PrefixValAddr": "crevaloper",
                "bech32PrefixValPub": "crevaloperpub",
                "bech32PrefixConsAddr": "crevalcons",
                "bech32PrefixConsPub": "crevalconspub"
            },
            "currencies": [
                {
                    "coinDenom": "CRE",
                    "coinMinimalDenom": "ucre",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "feeCurrencies": [
                {
                    "coinDenom": "CRE",
                    "coinMinimalDenom": "ucre",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "stakeCurrency": {
                "coinDenom": "CRE",
                "coinMinimalDenom": "ucre",
                "coinDecimals": "6",
                "coinGeckoId": "unknown"
            },
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        }
    }, 
    {
        id: "terra", values: 
        {
            "chainId": "phoenix-1",
            "chainName": "Terra V2",
            "rpc": "https://phoenix-rpc.terra.dev",
            "rest": "https://phoenix-lcd.terra.dev",
            "bip44": {
                "coinType": "330"
            },
            "coinType": "330",
            "bech32Config": {
                "bech32PrefixAccAddr": "terra",
                "bech32PrefixAccPub": "terrapub",
                "bech32PrefixValAddr": "terravaloper",
                "bech32PrefixValPub": "terravaloperpub",
                "bech32PrefixConsAddr": "terravalcons",
                "bech32PrefixConsPub": "terravalconspub"
            },
            "currencies": [
                {
                    "coinDenom": "LUNA",
                    "coinMinimalDenom": "uluna",
                    "coinDecimals": "6",
                    "coinGeckoId": "terra-luna"
                }
            ],
            "feeCurrencies": [
                {
                    "coinDenom": "LUNA",
                    "coinMinimalDenom": "uluna",
                    "coinDecimals": "6",
                    "coinGeckoId": "terra-luna"
                }
            ],
            "stakeCurrency": {
                "coinDenom": "LUNA",
                "coinMinimalDenom": "uluna",
                "coinDecimals": "6",
                "coinGeckoId": "terra-luna"
            },
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        }
    },        
    
];