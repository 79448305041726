import React, { useState, useEffect } from 'react';

const Countdown = ({ unixTimestamp }) => {
  const [timeRemaining, setTimeRemaining] = useState('');

  const calculateTimeRemaining = (targetTimestamp) => {
    const now = new Date().getTime();
    const difference = targetTimestamp - now;

    var result;

    if (difference <= 0) {
      return '0 days, 0 hours, 0 minutes';
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if( days > 0)
        result = `${days} days, ${hours} hours, ${minutes} minutes`;
    else if( days === 0 && hours === 0 && minutes === 0)
        result = `few seconds.`
    else if( days === 0 && hours > 0 )
        result = `${hours} hours, ${minutes} minutes`;
    else if( days === 0 && hours === 0 && minutes > 0 )
        result = `${minutes} minutes`;        
           
    return result;

  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(unixTimestamp * 1000));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [unixTimestamp]);

  return (
    <div>
      <p>{timeRemaining}</p>
    </div>
  );
};

export default Countdown;