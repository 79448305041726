import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};

    background: #1d1d2f;

    font-size: 20px !important;

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #swap-page {
    padding: 2px;
    background: linear-gradient(90deg,#8a86ff .04%,#e13cbd 99.5%);
  }

  a:hover {
    color: #f5f5f5 !important;
  }

  .hOQiSY, .hFOuav {
    background-color: #25252f;
    padding-left: 10px;
  }

  .eFSGuI {
    background-color: #151520;
    border-radius: 32px 32px 0 0;
    border-bottom: 2px solid #302e41;
  }

  .cFJSoL {
    border-bottom: 1px solid #4b4e63;
    padding: 24px;
    background-color: #151520;
    border-radius: 32px 32px 0 0;
  }

  .bJeyPI {
    padding: 24px;
    background-color: #151520;
    border-radius: 0 0 32px 32px;
  }

  .RfiiL {
    border-radius: 16px;
    background-color: #302e41;
    box-shadow: inset 0px 2px 2px -1px rgb(74 74 104 / 10%);
  }  

  .itaKkP {
    background-color: #151520;
    border-radius: 0 0 32px 32px;
  }

  .eYLOAZ {
    background-color: #343249 !important;
  }

  .dcfPHg {
    background-color: #343249 !important;
    margin-top: -15px;
    padding-top: 20px;
  }

  .htBBPI {
    margin-top: 10px;
  }

  .eWXhSz {
    border-radius: 0 0 32px 32px;
  }

  .gpcDdq {
    width: 24px;
    height: 24px;
    border-radius: 50px;
}

  .exxWpn {
    background-color: #3c3742 !important;
  }

  div, a { font-size: 18px !important; }

  .none { display: none !important; }

  .mylogo { 
    height: 40px !important;
    margin-left: -20px !important;
    width: 160px; 
  }

  .hTesTy, .fmgISU, .codUHV { background-color: #343249 !important; } 

  .farmsClass {
    width:100%;
    top: 0px;
    text-align: center;
  }


  .mcapValue {
    color: #6fb92b;
    font-size: 40px !important;
    font-weight: 600;
    text-align: left;
    padding: 5px;
  }

  .mcapTitle {
    color: #ffffff;
    font-size: 20px !important;
    font-weight: 200;
    text-align: left;
    padding: 5px;
  }  

  .maxSupply {
    color: #6fb92b;
    font-size: 30px;
    font-weight: 600;
  }

  .myloaderwhite {
    width: 22px;
    height: 22px;
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }  

    .myloadergreen {
      width: 22px;
      height: 22px;
      border: 3px solid #6fb92b;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }  

  .myloader {
    margin-top: 0px;
    width: 20px;
    height: 20px;
    border: 3px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }

  .farmsClass .ezFTwB { width: 100% !important; max-width: 100% !important; }

  .jGctjh { min-height: 10vh !important; }
  .kuefDH { min-height: 10vh !important; }

  .farmsClass .ddBdoI {
    background-color: #1d1d2f !important;
    border-radius: 0px !important;
  }

  div .blockLong {
    height: 120px;
    margin: 30px;
    width: 420px;
    border-radius: 20px;
    display: inline-table;
    background-image: linear-gradient(90deg,#8a86ff .04%,#e13cbd 99.5%);
    --tw-bg-opacity: 1;
    padding: 2px; 
  }


  div .blockShort {
    height: 100px;
    margin: 10px;
    width: 320px;
    border-radius: 20px;
    display: inline-table;
    background-image: linear-gradient(90deg,#8a86ff .04%,#e13cbd 99.5%);
    --tw-bg-opacity: 1;
    padding: 2px; 
  }  

  div .blockShort .mcapValue {
    font-size: 35px !important;
  }

  div .homeBlocks {
    height: 270px;
    margin: 30px;
    width: 270px;
    border-radius: 20px;
    display: inline-table;
    background-image: linear-gradient(90deg,#8a86ff .04%,#e13cbd 99.5%);
    --tw-bg-opacity: 1;
    padding: 2px; 
  }

  div .inside {
    width:100%;
    background-color: #28274d;
    border-radius: 20px;
    height: 100%;
    padding: 20px;
  }
  
  div .inside:hover {
    background-color: #2e2d4d;
  }

  div .inside img {
    height: 60px;
  }

  div.inside p.poolTitle {
    font-weight: 600;
    font-size: 28px;
    margin: 20px 0 20px 0;
    color: #ce7b1d;
  }

  div.inside p.poolText {
    font-size: 18px;
    margin: 10px 0 20px 0;
    color: #e5dbd7;
  }


  .poolTextRed {
    font-size: 20px;
    margin: 10px 0 20px 0;
    color: red !important;
  }  
 
  .poolTextRedGrid {
    font-size: 20px;
    margin: 10px 0 10px 0;
    color: red !important;
  }    

  div.inside button {
    background: #d4861f;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px;
    width: 200px;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 18px;
    cursor: pointer;
  }

  div.inside button:hover {
    background: #8d571d;
  }

  div .blockMain {
    text-align: center;
    width: 100%;
    margin-top: 0px;
  }
  
  div.connectWallet {
    border: 1px solid #fff;
    height: 270px;
    margin: 10px;
    width: 300px;
    border-radius: 10px;
    display: inline-table;
    background-color: #e5dbd7;  
  }
  
  div .connectWallet img.main {
    text-align: center;
    width: 80px;
    margin: 10px;
    border-radius: 50px;
    box-shadow: 5px 5px 10px #b9bbc4,
    -5px -5px 12px #fff; 
    margin-bottom: 30px; 
  }
  
  div.myHeader {
    padding: 0px 0px 20px 0px;
    display: block;
    text-align: center;
  }
  
  div.myHeader img {
    height: 70px;
  }

  div.myHeader .myTitle {
    color: #ce7b1d;
    font-size: 35px;
    font-weight: 500;
    padding-top: 10px;
    margin: 0px;
  }

  div.myHeader .myTitle a {
    font-size: 35px !important;
  }

  div.myHeader .mySub {
    color: #e5dbd7;
    font-size: 20px;
    font-weight: normal;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 900px;
  }

  div.myHeader .mySubTwo {
    color: #e5dbd7;
    font-size: 22px;
    font-weight: normal;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 900px;
    text-align: left;
  }
  
  div .connectWallet p.poolTitle {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 40px; 
  }
  
  div .blocks {
    border: 2px solid #e7dbd4;
    height: 270px;
    margin: 30px;
    width: 320px;
    border-radius: 30px;
    display: inline-table;
    background-color: #28274a;
    padding: 20px;
  }
  
  div .blocks img {
    text-align: center;
    width: 50px;
    margin: 10px;
    border-radius: 50px;
  }

  div .blocks img.img_left {
    margin-right: -30px;
    z-index: 1;
    position: relative;
  }
  
  div .blocks img.img_right {
    margin-right: 20px;
    z-index: -1;
  }


  div .boxGridPools img.img_left {
    margin-right: -10px;
    z-index: 1;
    position: relative;
    height: 35px !important;
    border-radius: 50px;
  }
  
  div .boxGridPools img.img_right {
    margin-right: 20px;
    z-index: -1;
    height: 35px !important;
    border-radius: 50px;
  }  

  div .boxGridPools .images {
    display: inline-block;
    float: left;
  }

  div .boxGridPools .poolTitle {
    display: inline-block;
    font-weight: 600;
    font-size: 20px !important;
    color: #e7dbd4;
    float: left;
    margin-top: 10px;
  }  

  div .boxGridPools .poolAPR {
    display: inline-block;
    font-weight: 600;
    font-size: 20px !important;
    color: #e7dbd4;
    margin-top: 10px;
  }
  
  div .boxGridPools .item button {
    background-color: #28a745;
    width: 100px;
    height: 35px;
    border: 1px solid #111;
    border-radius: 10px;
    color: #fafafa;
    font-size: 15px;
    font-weight: 600;
    margin-top: 2px;
  }

  div .boxGridPools .item button.disabled {  
    background-color: gray !important ;
  }

  .gridrows {
    margin-bottom: 10px;
  }

  .gridrows img {
    margin:10px;
    border: 1px solid #c7701b;
    border-radius: 10px;
    cursor: pointer;
  }
  
  div.blocks p.poolTitle {
    font-weight: 600;
    font-size: 26px;
    margin: 20px 0 20px 0;
    color: #e7dbd4;
  }
  
  div.blocks p.poolText {
    font-size: 16px;
    margin: 10px 0 20px 0;
    color: #e7dbd4;
  }

  
  div.blocks p.poolTextTwo {
    font-size: 18px;
    margin: 10px 0 20px 0;
    color: #e7dbd4;
  }

  
  div.blocks button {
    background: #4c9f6d;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding:10px;
    width: 200px;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  
  div.blocks button:hover {
    background: #3c885a;
  }

  div.buttons.center {
    text-align: center;
  }

  div.myUnlockWallet {
    text-align: center;
    width: 300px;
    display: inline-block;
  }  
  
  div.aprbox {
    width: 93%;
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    border: 2px solid #f3f3f3;
    height: 35px;
    color: #5b3926;
    font-size: 20px;
  }
  
  div.aprbox p {
    padding: 5px;
    font-size: 20px;
    font-weight: 600;
  }
  
  div.aprbox p.aprLeft { 
    float: left;
  }
  
  div.aprbox p.aprRight { 
    float: right; 
  }
  
  div.farmButtons {
    width:100%;
    padding: 10px;
    height: 70px;
  }
  
  div.farmButtons button.left {
    width: 120px;
    float:left;
  }
  
  div.farmButtons button.right {
    width: 120px;
    float:right;
    background-color: #d41414;
  }
  
  div.farmButtons button.right:hover {
    background-color: #c40f0f;
  }
  
  div.farmButtons button.claim {
    background-color: #19a168 !important;
    width: 170px;
  }
  
  .confirm {
    background-color: #19a168 !important;
    width: 110px;
  }

  div.farmButtons button.claim:hover {
    background-color: #1e9764 !important;
  }
  
  div.myStake {
    background-color: #28274a;
    border: 2px solid #e7dbd4;
    max-width: 560px;
    width: 100%;
    text-align: center;
    display: inline-table;
    margin: 10px;
    border-radius: 32px;
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  div.myStake p.stakeTitle {
    margin: 10px;
    font-size: 25px;
    font-weight: 600;
    color: #e7dbd4;
  }
  
  div.myStake p.stakeTitle span {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-top: 8px;
  }
  
  div.stakeInput {
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #a9aebf;
    display: inline-table;
    margin: 10px 0 10px 0;
  }
  
  .inputClass {
    background-color: #fff !important;
    height: 35px !important;
    border: none !important;
    width: 300px;
    padding: 0px;  
    font-size: 18px;
  }
  
  input:focus {
    outline: none !important;
  }
  
  span.spanInput {
    margin: 0 5px 0 5px;
    font-size: medium;
    font-weight: 600;
    color: #5b3926;
  }
  
  span.spanInput a {
    color: rgb(213, 93, 93);
    font-size: 12px;
    font-weight: 600;
  }

  span.spanInput a:hover {
    color: rgb(213, 93, 93) !important;
  }
  
  div.farmButtons button.confirm {
    background:#19a168;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding:10px;
    width: 110px;
    font-weight: 600;
    margin-bottom: 10px;  
    font-size: 18px;
  }
  
  span.warning {
    color: red;
    font-weight: 100;
    font-size: 16px;
  }

  span.warningTwo {
    padding: 10px;
    color: red;
    font-weight: 100;
    font-size: 20px;
  }  
  
  p.rewardsBalance {
    ont-family: 'Noto Sans', sans-serif !important;
    font-size: 45px !important;
    color: #e7dbd4;
    font-weight: normal;
    padding: 20px;
  }
  
  p.rewardsBalance span {
    font-size: 20px;
    display: block;
    padding-bottom: 20px;
  }
  
  p.txHead {
    color:#378d6d;
    font-size: 30px;
    font-weight: 500;
  }
  
  p.txLink {
    color:#1e92e6;
    font-size: 15px;
    font-weight: 500;
  }
  
  div.error {
    display: block;
    font-size: small;
    font-weight:bolder;
    color: red;
  }
  
  .btn-primary {
    border: none !important;
  }
  
  .mmask {
    box-shadow: none !important;
  }
  
  div .mygrid {
    background-color: #1d1d22;
    border-radius: 20px;
    width: 650px;
    text-align: center;
    box-shadow: 5px 5px 10px #333952, -5px -5px 12px #3c3b3b;
    display: inline-block;
    border: 1px solid #3c3b3b;
  }
  
  div.boxGrid {
    display: grid;
    grid-template-columns: 2rem 5rem 8rem 10rem 10rem;
    grid-gap: 1rem;
    padding: 15px;
    font-size: 18px;
    color: #f5f5f5;
    font-weight: 100; 
    border-bottom: 2px dashed #404c55;
    width: 650px;
  }

  div.boxGridText {
    display: grid;
    grid-template-columns: 18rem 25rem 10rem;
    grid-gap: 1rem;
    padding: 15px;
    font-size: 18px;
    color: #f5f5f5;
    font-weight: 100; 
    border-bottom: 2px dashed #404c55;
    width: 950px;
  }  



  div .mygridPools {
    background-color: #1d1d22;
    border-radius: 20px;
    width: 700px;
    text-align: center;
    box-shadow: 5px 5px 10px #333952, -5px -5px 12px #3c3b3b;
    display: inline-block;
    border: 1px solid #3c3b3b;
  }
  
  div.boxGridPools {
    display: grid;
    grid-template-columns: 22rem 10rem 5rem;
    grid-gap: 1rem;
    padding: 15px;
    font-size: 18px;
    color: #f5f5f5;
    font-weight: 100; 
    border-bottom: 2px dashed #404c55;
    width: 700px;
  }

  div.boxGridPools:last-child {
    border-bottom: none !important;
  }

  div.boxGridText {
    display: grid;
    grid-template-columns: 18rem 25rem 10rem;
    grid-gap: 1rem;
    padding: 15px;
    font-size: 18px;
    color: #f5f5f5;
    font-weight: 100; 
    border-bottom: 2px dashed #404c55;
    width: 950px;
  }   



  
  div.mycolor {
    background-color: #c7701b;
    border-radius: 20px 20px 0 0;
    color: #fff;
  }
  
  div.boxGrid:last-child {
    border-bottom: 0px solid #00ccff !important;
  }
  
  div.boxGrid .item {
    border: 0px solid #fff;
  }
  
  button.unlock {
    box-shadow: 5px 5px 10px rgb(0, 0, 0), -5px -5px 12px rgb(23, 4, 4) !important;
  }
  
  .myCount {
    color: #e5dbd7;
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0px 20px 0px;
  }

  div .myblock {
    margin: 10px !important;
  }

  .blue {
    background: #0d6efd;
    color: #fff;
    border-radius: 10px;
    height: 40px;
    width: 120px;
    font-size: 18px;
    cursor: pointer;
  }

  .blue:hover {
    background: #095cd5;
  }

  .spinner-border {
    float: left;
    margin-top: 5px;
  }

  div .myLoading {
    font-weight: 600;
    padding: 10px;
  }

  a:hover {
    color: none !important;
    text-decoration: none !important;
  }

  .fvBGUN {
    margin-top: 20px;
  }

  .myHome {
    min-width: 300px;
  }

  .myHome img {
    float: left;
    margin-right: 10px;
    height: 50px !important;
  }

  .myHome p {
    color: #ce7b1d;
    font-size: 30px !important;
    padding-top: 10px;
  }

  .myAPR {
    display: block;
    margin: 0px auto 10px auto;
    padding: 10px;
    background: #19a168;
    width: 200px;
    min-height: 45px;
    border-radius: 50px;
    border: 2px solid #0c6d45;
  }

  .myAPR p {
    font-size: 20px;
    font-weight: 600;
  }

  button.btn.btn-primary.bRight {
    height: 40px;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-weight: 600;
    padding: 0 10px;
    font-size: 16px;
}

.cUegiN { display: none !important; }

.roadmap { border: 5px solid #000000; border-radius: 20px; }

select {
  word-wrap: normal;
  border: none;
  font-size: 18px;
  font-weight: 600;
  width: 200px;
}


button.btn.btn-primary.claim:disabled {
  background: #1b7952 !important;
  cursor: default;
}

div.farmButtons button.right:disabled {
  background: #7f1a1a;
  cursor: default;
}

div.farmButtons button.left:disabled {
  background: #1b7952;
  cursor: default;
}

.myAPRLottery {
  display: inline-block;
  margin: 0px auto 10px auto;
  padding: 10px;
  background: #19a168;
  min-height: 45px;
  border-radius: 50px;
  border: 2px solid #0c6d45;
  margin: 0px 10px 10px 0px;
  font-weight: 600;
}

.myAPRLottery div { display: inline-block; }

.winningAddress {
  color: #6fb92b;
  font-weight: 600;
  font-size: 35px !important;
  margin: 20px;
}

.winningAddress div { display: inline-block; font-size: 35px !important; }

`

export default GlobalStyle
