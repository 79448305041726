import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { addresses } from '../components/Assets/addresses';
import { values } from '../components/Assets/values';
import { months } from '../components/Assets/months';
import lockerABI from '../components/abi/lockerContractABI.json'

const CNTO_Utility = () => { 

    const lockerAddress = addresses.locker.address;

    const [chainID, setChainID] = useState();
    const [address, setAddress] = useState('');
    const [myCount, setMyCount] = useState();
    const [lockID, setLockID] = useState();
    const [executeVal, setExecuteVal] = useState('');
    const [execute, setExecute] = useState(false);
    const [txValue, setTxValue] = useState('');
    const [success, setSuccess] = useState(false);
    const [chainError, setChainError] = useState(false);
    const [dataMain, setDataMain] = useState<any[]>([])
    const [valerror, setError] = useState('');
    const [activeButton, setActiveButton] = useState("");  

    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const signer = provider.getSigner();
    const lockerContract = new ethers.Contract(lockerAddress, lockerABI, signer);
  
    const theme = useContext(ThemeContext)
  
  
    return (
      <>
        <div className='farmsClass'>
        <AppBody>
          <Wrapper id="farms-page">
        

              <div className='blockMain'>
                <img className="roadmap" src="/images/cntoutility.png" />
              </div>
            
            <div className='myHeader'>
              <p className='myTitle'>Full info on Revenue Sharing Utility</p>
              <p className='mySubTwo'>
              Users who have provided liquidity at Ciento.exchange for any of the CNTO paired Pools and staked their LP tokens will receive a share from Rewards earned by all Synergy Nodes Validator Nodes across all chains. Synergy Nodes will allocate 50% of the revenue earned every month for this purpose. We are aiming to start this in the 2nd half of 2023.
                <br></br><br></br>
                This 50% of revenue will be shared between the following groups:
                <br></br><br></br>
                1. Majority of the share will go to the liquidity providers at Ciento.exchange for CNTO paired pools.
                <br></br><br></br>
                2. A small share will go to Liquidity providers to non-CNTO pools.
                <br></br><br></br>
                3. A small share will go to CNTO stakers.
                <br></br><br></br>
                4. Small share will go to those who have staked with any of the Synergy Nodes validator Nodes and also staked CNTO LP tokens. So, if you have staked with Syenrgy Nodes and also have provided liquidity to any of the CNTO pairs at our DEX, you will get rewards twice per month:
                <br></br><br></br>
                a. For just providing the liquidity to CNTO Pools
<br></br><br></br>
b. Plus also staking with our Validator Nodes at any of the chains.
<br></br><br></br>
When we arrive at the % of the share for each group specified above, we will inform the details to the community.
              </p>               
            </div>
  
  
          </Wrapper>
        </AppBody>
        </div>
      </>
    )
  };
  
  export default CNTO_Utility
  