import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { addresses } from '../components/Assets/addresses';
import farmABI from '../components/abi/farmContractABI.json'
import tokenABI from '../components/abi/tokenABI.json';
import { values } from '../components/Assets/values';

const Farms4 = () => {

    function CommaFormatted(amount) {
        var delimiter = ","; // replace comma if desired
        var a = amount.split('.',2)
        var d = a[1];
        var i = parseInt(a[0]);
        if(isNaN(i)) { return ''; }
        var minus = '';
        if(i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while(n.length > 3) {
          var nn = n.substr(n.length-3);
          a.unshift(nn);
          n = n.substr(0,n.length-3);
        }
        if(n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if(d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + amount;
        return amount;
      }      

    const live = true;

  const farmAddress = addresses.farm.address;

  const contractAddress = "";
  const [chainID, setChainID] = useState();
  const [address, setAddress] = useState('');
  const [accbalance, setBalance] = useState();
  const [chainError, setChainError] = useState(false);
  const [pSevenAPR, setpSevenAPR] = useState('');
  const [pEightAPR, setpEightAPR] = useState('');
  const [pNineAPR, setpNineAPR] = useState('');
  const [pTenAPR, setpTenAPR] = useState('');
  const [pElevenAPR, setpElevenAPR] = useState('');
  const [pTwelveAPR, setpTwelveAPR] = useState('');
  const [pThirteenAPR, setpThirteenAPR] = useState('');
  const [pFourteenAPR, setpFourteenAPR] = useState('');
  const [pFifteenAPR, setpFifteenAPR] = useState('');
  const [pSixteenAPR, setpSixteenAPR] = useState('');
  const [pSeventeenAPR, setpSeventeenAPR] = useState('');

  const [grid, setGrid] = useState(false);
  const [rows, setRows] = useState(true);  

  const provider = new ethers.providers.Web3Provider(window.ethereum as any);
  const signer = provider.getSigner();
  const farmContract = new ethers.Contract(farmAddress, farmABI, signer);

  useEffect(() => {

    const apr = async () => {

      let response = await fetch('https://testnet.ciento.exchange/prices');
      let data = await response.json();
      var acrePrice = data[0].price;
      var atomPrice = data[2].price;
      var osmoPrice = data[3].price;
      var axlPrice = data[6].price;
      var wbnbPrice = data[8].price;
      var wethPrice = data[9].price;
      var wbtcPrice = data[10].price;
      var wavaxPrice = data[11].price;
      var wmaticPrice = data[12].price;
      var cntoPrice = data[13].price;
      var statomPrice = data[16].price;
      var stosmoPrice = data[17].price;      
      var usdcPrice = 1;

      console.log("test1");

      //if(account) {

        var totalAllocPoint = await farmContract.totalAllocPoint();
        console.log("TotalAllocPoint = ", totalAllocPoint.toString());
        totalAllocPoint = totalAllocPoint.toString();
        var totalAllocPointNew = 2340;


        
        ///////////// Pool 16 stOSMO/USDC ////////////////////

        var tokenAddress = addresses[16].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var stosmoTotal = (result[0].toString() / 1000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var stosmoSingle = stosmoTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var stosmoUSD = (stosmoSingle * userLP) * stosmoPrice;
        var lpUSD = usdcUSD + stosmoUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365


        console.log("test2");

        //var allocPoint = await farmContract.poolInfo(7);
        //console.log("allocPoint = ", allocPoint[1].toString());
        //allocPoint = allocPoint[1].toString();
        var allocPointNew = 50;

        var myAPR = (((perday * (allocPointNew / totalAllocPointNew)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpSixteenAPR(CommaFormatted(myAPR.toFixed(2)));  




        ///////////// Pool 17 stATOM/USDC ////////////////////

        var tokenAddress = addresses[17].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var statomTotal = (result[0].toString() / 1000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var statomSingle = statomTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var statomUSD = (statomSingle * userLP) * statomPrice;
        var lpUSD = usdcUSD + statomUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365


        console.log("test2");

        //var allocPoint = await farmContract.poolInfo(7);
        //console.log("allocPoint = ", allocPoint[1].toString());
        //allocPoint = allocPoint[1].toString();
        var allocPointNew = 50;

        var myAPR = (((perday * (allocPointNew / totalAllocPointNew)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpSeventeenAPR(CommaFormatted(myAPR.toFixed(2)));          
        
        


        ///////////// Pool 7 ACRE/USDC ////////////////////

        var tokenAddress = addresses[7].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var acreTotal = (result[0].toString() / 1000000000000000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var acreSingle = acreTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var acreUSD = (acreSingle * userLP) * acrePrice;
        var lpUSD = usdcUSD + acreUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365


        console.log("test2");

        var allocPoint = await farmContract.poolInfo(7);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpSevenAPR(CommaFormatted(myAPR.toFixed(2)));




        ///////////// Pool 8 WBTC/USDC ////////////////////

        var tokenAddress = addresses[8].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var wbtcTotal = (result[0].toString() / 100000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var wbtcSingle = wbtcTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var wbtcUSD = (wbtcSingle * userLP) * wbtcPrice;
        var lpUSD = usdcUSD + wbtcUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(8);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpEightAPR(CommaFormatted(myAPR.toFixed(2)));        



        ///////////// Pool 9 WETH/USDC ////////////////////

        var tokenAddress = addresses[9].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var wethTotal = (result[0].toString() / 1000000000000000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var wethSingle = wethTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var wethUSD = (wethSingle * userLP) * wethPrice;
        var lpUSD = usdcUSD + wethUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(9);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpNineAPR(CommaFormatted(myAPR.toFixed(2)));           




        ///////////// Pool 10 ATOM/USDC ////////////////////

        var tokenAddress = addresses[10].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var atomTotal = (result[0].toString() / 1000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var atomSingle = atomTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var atomUSD = (atomSingle * userLP) * atomPrice;
        var lpUSD = usdcUSD + atomUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(10);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpTenAPR(CommaFormatted(myAPR.toFixed(2)));            




        ///////////// Pool 11 OSMO/USDC ////////////////////

        var tokenAddress = addresses[11].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var osmoTotal = (result[0].toString() / 1000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var osmoSingle = osmoTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var osmoUSD = (osmoSingle * userLP) * osmoPrice;
        var lpUSD = usdcUSD + osmoUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(11);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpElevenAPR(CommaFormatted(myAPR.toFixed(2)));            



        ///////////// Pool 12 AXL/USDC ////////////////////

        var tokenAddress = addresses[12].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var axlTotal = (result[0].toString() / 1000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var axlSingle = axlTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var axlUSD = (axlSingle * userLP) * axlPrice;
        var lpUSD = usdcUSD + axlUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(12);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpTwelveAPR(CommaFormatted(myAPR.toFixed(2)));            



        ///////////// Pool 13 WAVAX/USDC ////////////////////

        var tokenAddress = addresses[13].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var wavaxTotal = (result[0].toString() / 1000000000000000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var wavaxSingle = wavaxTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
        var lpUSD = usdcUSD + wavaxUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(13);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpThirteenAPR(CommaFormatted(myAPR.toFixed(2)));         




        ///////////// Pool 14 WBNB/USDC ////////////////////

        var tokenAddress = addresses[14].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var usdcTotal = (result[0].toString() / 1000000);
        var wbnbTotal = (result[1].toString() / 1000000000000000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var wbnbSingle = wbnbTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
        var lpUSD = usdcUSD + wbnbUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(14);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpFourteenAPR(CommaFormatted(myAPR.toFixed(2)));       



        ///////////// Pool 15 WMATIC/USDC ////////////////////

        var tokenAddress = addresses[15].address;
        var tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        var result = await tokenContract.getReserves();
        var wmaticTotal = (result[0].toString() / 1000000000000000000);
        var usdcTotal = (result[1].toString() / 1000000);
        result = await tokenContract.totalSupply();       
        var lpTotal = (result.toString() / 1000000000000000000);

        //var userLP = await farmContract.getDepositAmount(account, 1);
        var userLP = await tokenContract.balanceOf(farmAddress);
        userLP = userLP / 1000000000000000000;
        var usdcSingle = usdcTotal / lpTotal;
        var wmaticSingle = wmaticTotal / lpTotal;
        var usdcUSD = (usdcSingle * userLP) * usdcPrice;
        var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
        var lpUSD = usdcUSD + wmaticUSD;
        var perday = 1666.66 * cntoPrice;

        //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

        console.log("test2");

        var allocPoint = await farmContract.poolInfo(15);
        console.log("allocPoint = ", allocPoint[1].toString());
        allocPoint = allocPoint[1].toString();

        var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
        console.log("myAPR = ", myAPR.toFixed(2));
        setpFifteenAPR(CommaFormatted(myAPR.toFixed(2)));          




      //}
    
    }
      
    apr()
      .catch(console.error);       

  }, []);


  const getURLSeven = () => {
    window.location.assign('/#/farm/7');
  }

  const getURLEight = () => {
    window.location.assign('/#/farm/8');
  }
  
  const getURLNine = () => {
    window.location.assign('/#/farm/9');
  }
  
  const getURLTen = () => {
    window.location.assign('/#/farm/10');
  }  

  const getURLEleven = () => {
    window.location.assign('/#/farm/11');
  }  

  const getURLTwelve = () => {
    window.location.assign('/#/farm/12');
  }    

  const getURLThirteen = () => {
    window.location.assign('/#/farm/13');
  }  

  const getURLFourteen = () => {
    window.location.assign('/#/farm/14');
  }  
  
  const getURLFifteen = () => {
    window.location.assign('/#/farm/15');
  }    

  const getURLSixteen = () => {
    window.location.assign('/#/farm/16');
  }  

  const getURLSeventeen = () => {
    window.location.assign('/#/farm/17');
  } 
  
  const gridSubmit = () => {
    setGrid(true);
    setRows(false);
  } 
  
  const rowsSubmit = () => {
    setGrid(false);
    setRows(true);
  }     

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)


  return (
    <>
      <div className='farmsClass'>
      <AppBody>
        <Wrapper id="farms-page">




              <div className='buttons center'>   

                <div className='myHeader'>
                  <img src="/images/Ciento-Main.png" />
                  <p className='myTitle'>Ciento Exchange</p>
                  <p className='mySub'>CNTO Rewards for all New Pools will start from 8th March 2023.</p>  
                </div>
        
                { account ? "" :
                  <div className='myUnlockWallet'>
                    <ConnectWalletButton width="100%" />
                  </div>
                }
                
                { chainError === true ?
                  <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                  </div>
                : "" } 
        
              </div>

              { live ?
              <div className='blockMain'>

                <div className='gridrows'>
                  <img onClick={gridSubmit} src="/images/grid.png" />
                  <img onClick={rowsSubmit} src="/images/rows.png" />
                </div>


              { grid ?
              <div className='blockMain'>



                <div className='blocks'>
                    <img className='img_left' src="/images/logos/stosmo.png" />
                    <img className='img_right' src="/images/logos/usdc.png" />
                    <p className='poolTitle'>stOSMO-USDC Pool</p>
                    <p className='poolText'>Deposit stOSMO-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLSixteen}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pSixteenAPR ? pSixteenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                    <p className='poolTextRed'><br></br>Rewards starts from <br></br> <u>8th March 2023</u></p>
                </div> 



                <div className='blocks'>
                    <img className='img_left' src="/images/logos/statom.png" />
                    <img className='img_right' src="/images/logos/usdc.png" />
                    <p className='poolTitle'>stATOM-USDC Pool</p>
                    <p className='poolText'>Deposit stATOM-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLSeventeen}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pSeventeenAPR ? pSeventeenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                    <p className='poolTextRed'><br></br>Rewards starts from <br></br> <u>8th March 2023</u></p>                    
                </div>                                


                <div className='blocks'>
                    <img className='img_left' src="/images/logos/wacre.png" />
                    <img className='img_right' src="/images/logos/usdc.png" />
                    <p className='poolTitle'>ACRE-USDC Pool</p>
                    <p className='poolText'>Deposit ACRE-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLSeven}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pSevenAPR ? pSevenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div> 



                <div className='blocks'>
                    <img className='img_left' src="/images/logos/btc.png" />
                    <img className='img_right' src="/images/logos/usdc.png" />
                    <p className='poolTitle'>WBTC-USDC Pool</p>
                    <p className='poolText'>Deposit WBTC-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLEight}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pEightAPR ? pEightAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>   

                <div className='blocks'>
                    <img className='img_left' src="/images/logos/eth.png" />
                    <img className='img_right' src="/images/logos/usdc.png" />
                    <p className='poolTitle'>WETH-USDC Pool</p>
                    <p className='poolText'>Deposit WETH-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLNine}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pNineAPR ? pNineAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div> 

                <div className='blocks'>
                    <img className='img_left' src="/images/logos/atom.png" />
                    <img className='img_right' src="/images/logos/usdc.png" />
                    <p className='poolTitle'>ATOM-USDC Pool</p>
                    <p className='poolText'>Deposit ATOM-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLTen}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pTenAPR ? pTenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div> 

                <div className='blocks'>
                    <img className='img_left' src="/images/logos/osmo.png" />
                    <img className='img_right' src="images/logos/usdc.png" />
                    <p className='poolTitle'>OSMO-USDC Pool</p>
                    <p className='poolText'>Deposit OSMO-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLEleven}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pElevenAPR ? pElevenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>                                    


                <div className='blocks'>
                    <img className='img_left' src="/images/logos/axl.png" />
                    <img className='img_right' src="images/logos/usdc.png" />
                    <p className='poolTitle'>AXL-USDC Pool</p>
                    <p className='poolText'>Deposit AXL-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLTwelve}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pTwelveAPR ? pTwelveAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>  


                <div className='blocks'>
                    <img className='img_left' src="/images/logos/avax.png" />
                    <img className='img_right' src="images/logos/usdc.png" />
                    <p className='poolTitle'>WAVAX-USDC Pool</p>
                    <p className='poolText'>Deposit WAVAX-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLThirteen}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pThirteenAPR ? pThirteenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>




                <div className='blocks'>
                    <img className='img_left' src="/images/logos/bnb.png" />
                    <img className='img_right' src="images/logos/usdc.png" />
                    <p className='poolTitle'>WBNB-USDC Pool</p>
                    <p className='poolText'>Deposit WBNB-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLFourteen}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pFourteenAPR ? pFourteenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>  



                <div className='blocks'>
                    <img className='img_left' src="/images/logos/matic.png" />
                    <img className='img_right' src="images/logos/usdc.png" />
                    <p className='poolTitle'>WMATIC-USDC Pool</p>
                    <p className='poolText'>Deposit WMATIC-USDC LP <br></br>
                    Earn CNTO as Rewards</p>
                    { account ? <button onClick={getURLFifteen}>Select</button> : '' }
                    <div className='aprbox'>
                        <p className='aprLeft'>APR:</p>
                        <p className='aprRight'>{pFifteenAPR ? pFifteenAPR + ' %' : <span className="myloader"></span>}</p>
                    </div>
                </div>                  

              </div>

            : '' }





            { rows ? 

            <div className='blockMain mygridPools'>

                <div className="boxGridPools mycolor">
                  <div className='item'>Pool</div>
                  <div className='item'>APR</div>
                  <div className='item'></div>
                </div>




                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/stosmo.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>stOSMO-USDC Pool</div>
                    <br></br><br></br>
                    <p className='poolTextRedGrid'>Rewards starts from <u>8th March 2023</u></p>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pSixteenAPR ? pSixteenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLSixteen}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  



                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/statom.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>stATOM-USDC Pool</div>
                    <br></br><br></br>
                    <p className='poolTextRedGrid'>Rewards starts from <u>8th March 2023</u></p>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pSeventeenAPR ? pSeventeenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLSeventeen}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  




                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/acre.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>ACRE-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pSevenAPR ? pSevenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLSeven}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  




                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/btc.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>WBTC-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pEightAPR ? pEightAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLEight}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  



                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/eth.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>WETH-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pNineAPR ? pNineAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLNine}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  



                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/atom.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>ATOM-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pTenAPR ? pTenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLTen}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  




                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/osmo.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>OSMO-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pElevenAPR ? pElevenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLEleven}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  




                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/axl.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>AXL-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pTwelveAPR ? pTwelveAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLTwelve}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  




                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/avax.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>WAVAX-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pThirteenAPR ? pThirteenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLThirteen}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  





                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/bnb.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>WBNB-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pFourteenAPR ? pFourteenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLFourteen}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div>  




                <div className="boxGridPools">
                  <div className='item'>

                    <div className='images'>
                      <img className='img_left' src="/images/logos/matic.png" />
                      <img className='img_right' src="images/logos/usdc.png" />
                    </div>
                  
                    <div className='poolTitle'>WMATIC-USDC Pool</div>

                  </div>
                  <div className='item'>
                    <div className='poolAPR'>{pFifteenAPR ? pFifteenAPR + ' %' : <span className="myloaderwhite"></span>}</div>
                  </div>
                  <div className='item'>
                    { account ? <button onClick={getURLFifteen}>Select</button>
                      : <button disabled className='disabled'>Select</button> }   
                  </div>
                </div> 



              </div>

              : '' }


            </div>

             : <div></div> }  





        </Wrapper>
      </AppBody>
      </div>
    </>
  )
}

export default Farms4
