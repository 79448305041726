import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { addresses } from '../components/Assets/addresses';
import { values } from '../components/Assets/values';
import { months } from '../components/Assets/months';
import lockerABI from '../components/abi/lockerContractABI.json'

const SmartContracts = () => { 

    const lockerAddress = addresses.locker.address;

    const [chainID, setChainID] = useState();
    const [address, setAddress] = useState('');
    const [myCount, setMyCount] = useState();
    const [lockID, setLockID] = useState();
    const [executeVal, setExecuteVal] = useState('');
    const [execute, setExecute] = useState(false);
    const [txValue, setTxValue] = useState('');
    const [success, setSuccess] = useState(false);
    const [chainError, setChainError] = useState(false);
    const [dataMain, setDataMain] = useState<any[]>([])
    const [valerror, setError] = useState('');
    const [activeButton, setActiveButton] = useState("");  

    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const signer = provider.getSigner();
    const lockerContract = new ethers.Contract(lockerAddress, lockerABI, signer);
  
    useEffect(() => {   
  
    }, []); 
  
    const theme = useContext(ThemeContext)
  
  
    return (
      <>
        <div className='farmsClass'>
        <AppBody>
          <Wrapper id="farms-page">
  
  
  
  
                <div className='buttons center'>   
  
                  <div className='myHeader'>
                    <img src="/images/Ciento-Main.png" />
                    <p className='myTitle'>Ciento Exchange</p>
                    <p className='mySub'>Our DEX is a secure and reliable platform for trading digital assets. It offers a wide range of features, including low fees, fast transactions, and advanced security measures. With our DEX, users can easily access the global markets and trade with confidence. Our platform also provides a user-friendly interface that makes it easy to navigate and manage your trades.</p>                    
                    <p className='mySub'>Smart Contracts</p>
                  </div>
          
                </div>
        

              <div className='blockMain mygrid'>

                <div className="boxGridText mycolor">
                  <div className='item'>Name</div>
                  <div className='item'>Contract Address</div>
                  <div></div>
                </div>
                
                  <div className="boxGridText">
                    <div className='item'>CientoV2Factory</div>
                    <div className='item'>0x3B75beD64dbC8C9211c428c856d62e57fd925B84</div>
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0x3B75beD64dbC8C9211c428c856d62e57fd925B84' target='_blank'>Link</a></div>                              
                  </div>

                  <div className="boxGridText">
                    <div className='item'>CientoV2Router02</div>
                    <div className='item'>0x561dDD832dcbFC2635FF76e7A9a4d4e74279A744</div>
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0x561dDD832dcbFC2635FF76e7A9a4d4e74279A744' target='_blank'>Link</a></div>                           
                  </div>                  

                  <div className="boxGridText">
                    <div className='item'>WACRE Address</div>
                    <div className='item'>0x97C2D9e1D01Fb716c587Bc9AffcA5d795eef9FE1</div>
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0x97C2D9e1D01Fb716c587Bc9AffcA5d795eef9FE1' target='_blank'>Link</a></div>                             
                  </div> 

                  <div className="boxGridText">
                    <div className='item'>CNTO Token</div>
                    <div className='item'>0xAE6D3334989a22A65228732446731438672418F2</div>
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0xAE6D3334989a22A65228732446731438672418F2' target='_blank'>Link</a></div>                
                  </div>

                  <div className="boxGridText">
                    <div className='item'>CNTO Farming</div>
                    <div className='item'>0x562Dd9Cd2951e4bCa7a6e424aaB67b0e30Eb624e</div>   
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0x562Dd9Cd2951e4bCa7a6e424aaB67b0e30Eb624e' target='_blank'>Link</a></div>                          
                  </div>   

                  <div className="boxGridText">
                    <div className='item'>CNTO Staking</div>
                    <div className='item'>0xBb1287CA8d7dA4109f4DB91c0B2332ddE24286E3</div>  
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0xBb1287CA8d7dA4109f4DB91c0B2332ddE24286E3' target='_blank'>Link</a></div>                          
                  </div>   

                  <div className="boxGridText">
                    <div className='item'>CNTO Staking Old</div>
                    <div className='item'>0xdee7733F53839Ab13865ac0E8196F521EB44B204</div>  
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0xdee7733F53839Ab13865ac0E8196F521EB44B204' target='_blank'>Link</a></div>                          
                  </div>                    

                  <div className="boxGridText">
                    <div className='item'>CNTO Stake Token</div>
                    <div className='item'>0x43b78D609f0eb25D90e0e5FD069469382a173E75</div>  
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0x43b78D609f0eb25D90e0e5FD069469382a173E75' target='_blank'>Link</a></div>                           
                  </div>   

                  <div className="boxGridText">
                    <div className='item'>CNTO Rewards Holder</div>
                    <div className='item'>0x4bF3EC47F961eB4773a1Ed9B89c21f80cbEd7405</div> 
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0x4bF3EC47F961eB4773a1Ed9B89c21f80cbEd7405' target='_blank'>Link</a></div>                          
                  </div>   

                  <div className="boxGridText">
                    <div className='item'>CNTO Rewards Distributor</div>
                    <div className='item'>0x2d8A2746EdB30591Fe93186C091d558092cDaaeB</div>  
                    <div className='item'><a href='https://acrescout.mindheartsoul.org/address/0x2d8A2746EdB30591Fe93186C091d558092cDaaeB' target='_blank'>Link</a></div>              
                  </div>                                                                                                                                               

              </div>
  
  
  
          </Wrapper>
        </AppBody>
        </div>
      </>
    )
  };
  
  export default SmartContracts
  