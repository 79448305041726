import { config } from '../components/Wallets/config';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import AppBody from './AppBody'

const Keplr = () => {

  useEffect(() => {
        console.log("Test");
    }, []);


    const connectKeplr = async (e) => {
        e.preventDefault();
        const { keplr } = window
        if (!keplr) {
            alert("You need to install Keplr")
            return
        }
    
        const getConfig = config.find(obj => {
          return obj.id === 'acrechain';
        });
    
        if(getConfig) {
          await keplr.experimentalSuggestChain(getConfig.values);
        }

        const chainId = 'acre_9052-1';
        await window.keplr.enable(chainId);        
    }

  return (
    <>
      <div className='farmsClass'>
      <AppBody>
        <Wrapper id="farms-page">




            <div className='buttons center'>   

                <div className='myHeader'>
                  <img src="/images/Ciento-Main.png" />
                  <p className='myTitle'>Ciento Exchange</p>
                  <p className='mySub'>Add Acrechain Mainnet to Keplr Wallet. This will make CNTO visible on Keplr Wallet!</p>
                </div>

                <button type="button" onClick={connectKeplr} className="btn btn-primary bRight">Add Acrechain to Keplr</button>
        
            </div>


        </Wrapper>
      </AppBody>
      </div>
    </>
  )
}

export default Keplr
