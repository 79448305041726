import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { values } from '../components/Assets/values';

const Home2 = () => {

  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const contractAddress = "";
  const [chainID, setChainID] = useState();
  const [address, setAddress] = useState('');
  const [accbalance, setBalance] = useState();
  const [chainError, setChainError] = useState(false);
  const [cntoPrice, setcntoPrice] = useState('');
  const [cntoMCAP, setcntoMCAP] = useState('');
  const [tvl, setTVL] = useState('');

  useEffect(() => {

    const apr = async () => {

      let response = await fetch('https://testnet.ciento.exchange/prices');
      let data = await response.json();
      var acrePrice = data[0].price;
      var cntoPrice = data[13].price;
      var tvl = data[14].price;

      setcntoPrice(cntoPrice.toFixed(2).toString());
      setTVL(currencyFormat(tvl).toString());      

      var mcap = 1000000 * cntoPrice;
      if(mcap > 1000000)
        setcntoMCAP((mcap / 1000000).toFixed(2) + " Million");
      else
        setcntoMCAP(currencyFormat(mcap.toFixed(2)).toString());
    
    }
      
    apr()
      .catch(console.error);       

  }, []);  


  const getURLOne = () => {
    window.location.assign('/#/swap');
  }
  
  const getURLTwo = () => {
    window.location.assign('/#/farms');
  }
  
  const getURLThree = () => {
    window.location.assign('/#/stake');
  }  

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)


  return (
    <>
      <div className='farmsClass'>
      <AppBody>
        <Wrapper id="farms-page">




              <div className='buttons center'>   

                <div className='myHeader'>
                  <img src="/images/Ciento-Main.png" />
                  <p className='myTitle'>Ciento Exchange</p>
                </div>
                <p className='maxSupply'>Max Supply: 1 Million CNTO</p>
        
                { account ? "" :
                  <div className='myUnlockWallet'>
                    <ConnectWalletButton width="100%" />
                  </div>
                }
                
                { chainError === true ?
                  <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                  </div>
                : "" } 
        
              </div>

              <div className='blockMain'>
                <div className='blockLong'>
                    <div className='inside'>
                      <div className='mcapValue'>USD { cntoMCAP }</div>
                        <div className='mcapTitle'>Fully Diluted Market Cap</div>
                    </div>
                </div>
                <div className='blockLong'>
                    <div className='inside'>
                      <div className='mcapValue'>USD { tvl }</div>
                        <div className='mcapTitle'>Total Value Locked (TVL)</div>
                    </div>
                </div>
              </div>

              <div className='blockMain'>
                <div className='blockLong'>
                    <div className='inside'>
                      <div className='mcapValue'>Coming Soon....</div>
                        <div className='mcapTitle'>Circulating Supply</div>
                    </div>
                </div>
                <div className='blockLong'>
                    <div className='inside'>
                      <div className='mcapValue'>USD { cntoPrice }</div>
                        <div className='mcapTitle'>CNTO Price</div>
                    </div>
                </div>
              </div>                          

              <div className='blockMain'>
                <div className='homeBlocks'>
                    <div className='inside'>
                        <img src="https://backups.synergynodes.com/images/logos/ciento.png" />
                        <p className='poolTitle'>Exchange</p>
                        <p className='poolText'>Swap any Tokens within few seconds!</p>
                        <button onClick={getURLOne}>Go to Exchange</button>
                    </div>                         
                </div>
                <div className='homeBlocks'>
                    <div className='inside'>
                        <img src="https://backups.synergynodes.com/images/logos/farming-main.png" />
                        <p className='poolTitle'>Farming</p>
                        <p className='poolText'>Earn CNTO Rewards for providing Liquidity!</p>
                        <button onClick={getURLTwo}>Go to Farming</button>
                    </div>                        
                </div>
                <div className='homeBlocks'>
                    <div className='inside'>
                        <img src="https://backups.synergynodes.com/images/logos/staking-main.png" />
                        <p className='poolTitle'>Staking</p>
                        <p className='poolText'>Stake CNTO Tokens to earn CNTO Rewards!</p>
                        <button onClick={getURLThree}>Go to Staking</button>
                    </div>                
                </div>                            
              </div>





        </Wrapper>
      </AppBody>
      </div>
    </>
  )
}

export default Home2
