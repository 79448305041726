import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { addresses } from '../components/Assets/addresses';
import { values } from '../components/Assets/values';
import { months } from '../components/Assets/months';
import lockerABI from '../components/abi/lockerContractABI.json'

const Unbondings = () => { 

    
    const { account } = useActiveWeb3React();
    const theme = useContext(ThemeContext);

    const live = true;

    const lockerAddress = addresses.locker.address;

    const [chainID, setChainID] = useState();
    const [address, setAddress] = useState('');
    const [myCount, setMyCount] = useState();
    const [lockID, setLockID] = useState();
    const [executeVal, setExecuteVal] = useState('');
    const [execute, setExecute] = useState(false);
    const [txValue, setTxValue] = useState('');
    const [success, setSuccess] = useState(false);
    const [chainError, setChainError] = useState(false);
    const [dataMain, setDataMain] = useState<any[]>([])
    const [valerror, setError] = useState('');
    const [activeButton, setActiveButton] = useState("");  
  
    useEffect(() => {
  
      const connectMetaMask = async () => {
        if(account)
        {        
          getAccount();
        }
      }
        
      connectMetaMask()
        .catch(console.error);       
  
    }, []);
    
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );    
  
    const getAccount = async () => {

      const provider = new ethers.providers.Web3Provider(window.ethereum as any);
      const signer = provider.getSigner();
      const lockerContract = new ethers.Contract(lockerAddress, lockerABI, signer);  
        const address = await signer.getAddress();
        setAddress(address);

        //var arrData: string[] = [];
        var arrData: any[] = [];
        var count = await lockerContract.getLockCounts(address);

        await delay(100);

        setMyCount(count.toNumber());

        for (var i = 0; i < count.toNumber(); i++) {
        
          var lid = await lockerContract.userLockIds(address, i);
          var data = await lockerContract.locks(lid);

          var t = new Date(data[5].toNumber() * 1000);
          var formatted = ('0' + t.getDate()).slice(-2) + ' ' + months[t.getMonth()].month + ' ' + t.getFullYear() + ' - ' + ('0' + t.getHours()).slice(-2) + ':' + ('0' + t.getMinutes()).slice(-2);

          var action = "";

          if(Date.now() > data[5].toNumber() * 1000 && data[6] == false)
            action = "claim";
          else if(Date.now() > data[5].toNumber() * 1000 && data[6] == true)
            action = "Claimed";
          else
            action = "Still Locked";

          arrData.push({"id": lid, "token": data[0], "name": values[data[0]].name, "amount": parseFloat(ethers.utils.formatUnits(data[3].toString(), values['decimals'])).toFixed(2), "unlock": formatted, "action": action });  
          //await delay(100);

        }

        setDataMain(arrData);

      }

      const handleRelease = async (e) => {
        e.preventDefault();
        setLockID(e.target.id);
        setExecute(true);
        setError('');
        setExecuteVal('spinner-border spinner-border-sm mr-1');

        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum as any);
          const signer = provider.getSigner();
          const lockerContract = new ethers.Contract(lockerAddress, lockerABI, signer);          
          const result = await lockerContract.releaseLock(e.target.id);
          await result.wait();
          setSuccess(true);
          setTxValue(values['explorer'] + "tx/" + result.hash);
          setExecute(false);
          setExecuteVal('');
          getAccount();
        } catch (error) {
          console.error(error);
        }
      }
  
  
    return (
      <>
        <div className='farmsClass'>
        <AppBody>
          <Wrapper id="farms-page">
  
  
  
  
                <div className='buttons center'>   
  
                  <div className='myHeader'>
                    <img src="/images/Ciento-Main.png" />
                    <p className='myTitle'>Ciento Exchange</p>
                    <p className='mySub'>Swap Tokens, earn CNTO Rewards for Liquidty Mining and Staking!</p>
                  </div>
          
                  { account ? "" :
                    <div className='myUnlockWallet'>
                      <ConnectWalletButton width="100%" />
                    </div>
                  }
                  
                  { chainError === true ?
                    <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                    </div>
                  : "" } 
          
                </div>

            { !myCount ?
              <div className='myCount'>
                No Records found for the connected Wallet.
              </div>
              : "" 
            }                

            { account && myCount ?
              <div className='myCount'>
                NOTE: You should see {myCount} entries below. If not, please reload the page.
              </div>
              : "" 
            }
  
            { success ?
              <div className='myStake'>
                <div className='success'>
                  <p className='txHead'>Success!</p>
                  <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
                </div>
              </div>
              : "" 
            }
  
        
            { account && myCount && live ?
              <div className='blockMain mygrid'>

                <div className="boxGrid mycolor">
                  <div className='item'></div>
                  <div className='item'>Lock Duration</div>
                  <div className='item'>Asset</div>
                  <div className='item'>Unlock on</div>
                  <div className='item'>Status</div>
                </div>

                {dataMain.length === 0 ? <div className='myLoading'>Loading ......</div> : ""}
                
                {dataMain.map((item, index) => (
                  <div key={index} className="boxGrid">
                  <div className='item'>{index + 1}.</div>
                    <div className='item'>1 Day</div>
                    <div className='item'>{item.amount} {item.name}</div>
                    <div className='item'>{item.unlock}</div>
                    <div className='item'>
                      {item.action === "claim" ?
                        <form id={item.id} onSubmit={handleRelease} >
                          <button
                            type="submit"
                            key={item.id}
                            onClick={() => {setActiveButton(item.id);}}
                            className="btn btn-primary unlock"
                            disabled={execute ? true : false }
                          >
                            <span className={activeButton === item.id ? executeVal : ''}></span> Claim
                          </button>
                        </form>
                        : item.action
                      }
                    </div>
                  </div>
                ))}

              </div>
              : ''
            }
  
  
  
          </Wrapper>
        </AppBody>
        </div>
      </>
    )
  };
  
  export default Unbondings
  