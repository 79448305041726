import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from './AppBody'

import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { addresses } from '../components/Assets/addresses';
import farmABI from '../components/abi/farmContractABI.json'
import tokenABI from '../components/abi/tokenABI.json';
import { values } from '../components/Assets/values';

const Farm2 = () => { 

    function CommaFormatted(amount) {
        var delimiter = ","; // replace comma if desired
        var a = amount.split('.',2)
        var d = a[1];
        var i = parseInt(a[0]);
        if(isNaN(i)) { return ''; }
        var minus = '';
        if(i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while(n.length > 3) {
          var nn = n.substr(n.length-3);
          a.unshift(nn);
          n = n.substr(0,n.length-3);
        }
        if(n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if(d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + amount;
        return amount;
      }    

  function expo(x, f) {
    return Number.parseFloat(x).toExponential(f);
  }

  function counter(id, start, end, duration) {
    let obj = document.getElementById(id),
     current = start,
     range = end - start,
     increment = end > start ? 1 : -1,
     step = Math.abs(Math.floor(duration / range)),
     timer = setInterval(() => {
      current += increment;
      obj.textContent = current + '.000';
      if (current > end) {
       clearInterval(timer);
       obj.textContent = end;
      }
     }, step);
  }

  const { id } = useParams<{ id: string }>();
  console.log("myID = ", id);

  const live = true;

  const farmAddress = addresses.farm.address;
  const tokenAddress = addresses[id].address;
  console.log("Farm Address : ", farmAddress);
  console.log("Token Address : ", tokenAddress);
  const [chainID, setChainID] = useState();
  const [address, setAddress] = useState('');
  const [tokenbalance, setTokenBalance] = useState('');
  const [stakeInputVal, setStakeInputVal] = useState('');
  const [unstakeInputVal, setUnstakeInputVal] = useState('');
  const [rewardsbalance, setRewardsBalance] = useState('');
  const [stakedbalance, setStakedBalance] = useState('');
  const [stakeOnValue, setStakeOnValue] = useState('');
  const [unstakeOnValue, setUnstakeOnValue] = useState('');
  const [allowance, setAllowance] = useState('');
  const [allowanceFlag, setAllowanceFlag] = useState(false);
  const [approve, setApprove] = useState(false);
  const [spinner, setSpinnerVal] = useState('');
  const [spinner_claim, setSpinnerClaimVal] = useState('');
  const [executeVal, setExecuteVal] = useState('');
  const [execute, setExecute] = useState(false);
  const [txValue, setTxValue] = useState('');
  const [success, setSuccess] = useState(false);
  const [chainError, setChainError] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);    
  const [valerror, setError] = useState('');
  const [pOneAPR, setpOneAPR] = useState('');
  const [pOneAPRTwo, setpOneAPRTwo] = useState('');

  const [poolTVL, setpoolTVL] = useState('');
  const [farmTVL, setfarmTVL] = useState('');
  const [userTVL, setuserTVL] = useState('');

  const [stakedbalanceTwo, setStakedBalanceTwo] = useState('');
  const [tokenbalanceTwo, setTokenBalanceTwo] = useState('');

  const provider = new ethers.providers.Web3Provider(window.ethereum as any);
  const signer = provider.getSigner();
  const farmContract = new ethers.Contract(farmAddress, farmABI, signer);
  const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
  //const tokenContract = useTokenContract(tokenAddress);

  useEffect(() => {

    const connectMetaMask = async () => {
        const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        console.log("MyAddress = ", account);
        console.log("signer = ", signer);
        var balance = await tokenContract.balanceOf(account);
        console.log("Balance 1 = ", balance.toString());        
        getAccount();

        let response = await fetch('https://testnet.ciento.exchange/prices');
        let data = await response.json();
        var acrePrice = data[0].price;
        var atomPrice = data[2].price;
        var osmoPrice = data[3].price;
        var axlPrice = data[6].price;
        var wbnbPrice = data[8].price;
        var wethPrice = data[9].price;
        var wbtcPrice = data[10].price;
        var wavaxPrice = data[11].price;
        var wmaticPrice = data[12].price;
        var cntoPrice = data[13].price;
        var usdcPrice = 1;
  
        if(account) {

            if(id === '0')
            {
                ///////////// Pool 0 ////////////////////

                var result = await tokenContract.getReserves();
                var acreTotal = (result[0].toString() / 1000000000000000000);
                var cntoTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (acreTotal * acrePrice) + (cntoTotal * cntoPrice);
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

        
                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var acreSingle = acreTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var acreUSD = (acreSingle * userLP) * acrePrice;
                var lpUSD = cntoUSD + acreUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var acreSingle = acreTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var acreUSD = (acreSingle * userLP) * acrePrice;
                var userlpUSD = cntoUSD + acreUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2)));                
        
                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365
        
                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();
        
                console.log("test2");
        
                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();
        
                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));
        
                var myAPR = (((perday * (300 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPRTwo(CommaFormatted(myAPR.toFixed(2)));
            }
            else if(id === '1')
            {
                ///////////// Pool 1 CNTO/ATOM ////////////////////
                var result = await tokenContract.getReserves();
                var atomTotal = (result[0].toString() / 1000000);
                var cntoTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (atomTotal * atomPrice) + (cntoTotal * cntoPrice);
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));


                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var atomSingle = atomTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var atomUSD = (atomSingle * userLP) * atomPrice;
                var lpUSD = cntoUSD + atomUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var atomSingle = atomTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var atomUSD = (atomSingle * userLP) * atomPrice;
                var userlpUSD = cntoUSD + atomUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2)));

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(myAPR.toFixed(2));

                var myAPR = (((perday * (200 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));
        

            }
            else if(id === '2')
            {

                ///////////// Pool 2 CNTO/OSMO ////////////////////
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var osmoTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (osmoTotal * osmoPrice) + (cntoTotal * cntoPrice);
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var osmoSingle = osmoTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var osmoUSD = (osmoSingle * userLP) * osmoPrice;
                var lpUSD = cntoUSD + osmoUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var osmoSingle = osmoTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var osmoUSD = (osmoSingle * userLP) * osmoPrice;
                var userlpUSD = cntoUSD + osmoUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(myAPR.toFixed(2));

                var myAPR = (((perday * (200 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));   

            }
            else if(id === '3')
            {

                ///////////// Pool 3 CNTO/WBNB ////////////////////
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var wbnbTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wbnbTotal * wbnbPrice) + (cntoTotal * cntoPrice);
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wbnbSingle = wbnbTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
                var lpUSD = cntoUSD + wbnbUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wbnbSingle = wbnbTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
                var userlpUSD = cntoUSD + wbnbUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(myAPR.toFixed(2));

                var myAPR = (((perday * (100 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));  

            }
            else if(id === '4')
            {

                ///////////// Pool 4 CNTO/WMATIC ////////////////////
                var result = await tokenContract.getReserves();
                var wmaticTotal = (result[0].toString() / 1000000000000000000);
                var cntoTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wmaticTotal * wmaticPrice) + (cntoTotal * cntoPrice);
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));
                
                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wmaticSingle = wmaticTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
                var lpUSD = cntoUSD + wmaticUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wmaticSingle = wmaticTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
                var userlpUSD = cntoUSD + wmaticUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(myAPR.toFixed(2));

                var myAPR = (((perday * (100 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));   

            }
            else if(id === '5')
            {

                ///////////// Pool 5 CNTO/WAVAX ////////////////////
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var wavaxTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wavaxTotal * wavaxPrice) + (cntoTotal * cntoPrice);
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wavaxSingle = wavaxTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
                var lpUSD = cntoUSD + wavaxUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var wavaxSingle = wavaxTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
                var userlpUSD = cntoUSD + wavaxUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(myAPR.toFixed(2));

                var myAPR = (((perday * (100 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));  

            }
            else if(id === '6')
            {

                ///////////// Pool 6 CNTO/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var cntoTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (cntoTotal * cntoPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var lpUSD = cntoUSD + usdcUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var cntoSingle = cntoTotal / lpTotal;
                var usdcSingle = usdcTotal / lpTotal;
                var cntoUSD = (cntoSingle * userLP) * cntoPrice;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var userlpUSD = cntoUSD + usdcUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(myAPR.toFixed(2));

                var myAPR = (((perday * (200 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));                  


            }
            else if(id === '7')
            {

                ///////////// Pool 7 ACRE/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var acreTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (acreTotal * acrePrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var acreSingle = acreTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var acreUSD = (acreSingle * userLP) * acrePrice;
                var lpUSD = usdcUSD + acreUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var acreSingle = acreTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var acreUSD = (acreSingle * userLP) * acrePrice;
                var userlpUSD = usdcUSD + acreUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                var myAPR = (((perday * (200 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));                


            }
            else if(id === '8')
            {

                ///////////// Pool 8 WBTC/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var wbtcTotal = (result[0].toString() / 100000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wbtcTotal * wbtcPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wbtcSingle = wbtcTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wbtcUSD = (wbtcSingle * userLP) * wbtcPrice;
                var lpUSD = usdcUSD + wbtcUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wbtcSingle = wbtcTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wbtcUSD = (wbtcSingle * userLP) * wbtcPrice;
                var userlpUSD = usdcUSD + wbtcUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                console.log("lpUSD = ", lpUSD);
                var myAPR = (((perday * (50 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));    

            }
            else if(id === '9')
            {

                ///////////// Pool 9 WETH/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var wethTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wethTotal * wethPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wethSingle = wethTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wethUSD = (wethSingle * userLP) * wethPrice;
                var lpUSD = usdcUSD + wethUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wethSingle = wethTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wethUSD = (wethSingle * userLP) * wethPrice;
                var userlpUSD = usdcUSD + wethUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                console.log("lpUSD = ", lpUSD);
                var myAPR = (((perday * (50 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));                   

            }
            else if(id === '10')
            {

                ///////////// Pool 10 ATOM/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var atomTotal = (result[0].toString() / 1000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (atomTotal * atomPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var atomSingle = atomTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var atomUSD = (atomSingle * userLP) * atomPrice;
                var lpUSD = usdcUSD + atomUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var atomSingle = atomTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var atomUSD = (atomSingle * userLP) * atomPrice;
                var userlpUSD = usdcUSD + atomUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                console.log("lpUSD = ", lpUSD);
                var myAPR = (((perday * (60 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));   

            }
            else if(id === '11')
            {

                ///////////// Pool 11 OSMO/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var osmoTotal = (result[0].toString() / 1000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (osmoTotal * osmoPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var osmoSingle = osmoTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var osmoUSD = (osmoSingle * userLP) * osmoPrice;
                var lpUSD = usdcUSD + osmoUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var osmoSingle = osmoTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var osmoUSD = (osmoSingle * userLP) * osmoPrice;
                var userlpUSD = usdcUSD + osmoUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2)));

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                console.log("lpUSD = ", lpUSD);
                var myAPR = (((perday * (80 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));    

            }
            else if(id === '12')
            {

                ///////////// Pool 12 AXL/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var axlTotal = (result[0].toString() / 1000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (axlTotal * axlPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var axlSingle = axlTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var axlUSD = (axlSingle * userLP) * axlPrice;
                var lpUSD = usdcUSD + axlUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var axlSingle = axlTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var axlUSD = (axlSingle * userLP) * axlPrice;
                var userlpUSD = usdcUSD + axlUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                console.log("lpUSD = ", lpUSD);
                var myAPR = (((perday * (50 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));    

            }
            else if(id === '13')
            {

                ///////////// Pool 13 WAVAX/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var wavaxTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wavaxTotal * wavaxPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wavaxSingle = wavaxTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
                var lpUSD = usdcUSD + wavaxUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wavaxSingle = wavaxTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wavaxUSD = (wavaxSingle * userLP) * wavaxPrice;
                var userlpUSD = usdcUSD + wavaxUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                console.log("lpUSD = ", lpUSD);
                var myAPR = (((perday * (50 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));  

            }
            else if(id === '14')
            {

                ///////////// Pool 14 WBNB/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var usdcTotal = (result[0].toString() / 1000000);
                var wbnbTotal = (result[1].toString() / 1000000000000000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wbnbTotal * wbnbPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wbnbSingle = wbnbTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
                var lpUSD = usdcUSD + wbnbUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wbnbSingle = wbnbTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wbnbUSD = (wbnbSingle * userLP) * wbnbPrice;
                var userlpUSD = usdcUSD + wbnbUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2))); 

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                console.log("lpUSD = ", lpUSD);
                var myAPR = (((perday * (50 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));  

            }
            else if(id === '15')
            {

                ///////////// Pool 15 WMATIC/USDC ////////////////////
                var result = await tokenContract.getReserves();
                var wmaticTotal = (result[0].toString() / 1000000000000000000);
                var usdcTotal = (result[1].toString() / 1000000);
                result = await tokenContract.totalSupply();       
                var lpTotal = (result.toString() / 1000000000000000000);
                var poolTVL = (wmaticTotal * wmaticPrice) + usdcTotal;
                setpoolTVL(CommaFormatted(poolTVL.toFixed(2)));

                //var userLP = await farmContract.getDepositAmount(account, 1);
                var userLP = await tokenContract.balanceOf(farmAddress);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wmaticSingle = wmaticTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
                var lpUSD = usdcUSD + wmaticUSD;
                var perday = 1666.66 * cntoPrice;
                setfarmTVL(CommaFormatted(lpUSD.toFixed(2)));

                var userLP = await farmContract.getDepositAmount(account, id);
                userLP = userLP / 1000000000000000000;
                var usdcSingle = usdcTotal / lpTotal;
                var wmaticSingle = wmaticTotal / lpTotal;
                var usdcUSD = (usdcSingle * userLP) * usdcPrice;
                var wmaticUSD = (wmaticSingle * userLP) * wmaticPrice;
                var userlpUSD = usdcUSD + wmaticUSD;
                setuserTVL(CommaFormatted(userlpUSD.toFixed(2)));

                //(1000 CNTO * CNTO price) * (allocPoint / totallAllocPoint) / (total funds in the pool in USD Value) * 365

                var totalAllocPoint = await farmContract.totalAllocPoint();
                console.log("TotalAllocPoint = ", totalAllocPoint.toString());
                totalAllocPoint = totalAllocPoint.toString();

                console.log("test2");

                var allocPoint = await farmContract.poolInfo(0);
                console.log("allocPoint = ", allocPoint[1].toString());
                allocPoint = allocPoint[1].toString();

                var myAPR = (((perday * (allocPoint / totalAllocPoint)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                //setpOneAPR(myAPR.toFixed(2));

                console.log("lpUSD = ", lpUSD);
                var myAPR = (((perday * (50 / 1840)) / lpUSD) * 365) * 100;
                console.log("myAPR = ", myAPR.toFixed(2));
                setpOneAPR(CommaFormatted(myAPR.toFixed(2)));    

            }
            
        }

    }
      
    connectMetaMask()
      .catch(console.error);       

  }, []);  

  const getAccount = async () => {
    //const balance = await provider.getBalance(address);
    var balance = await tokenContract.balanceOf(account);
    console.log("Balance 1 = ", balance);
    //const address = await signer.getAddress();
    //setAddress(account);
    setTokenBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(18));

    balance = await farmContract.getDepositAmount(account, id);
    setStakedBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(18));
    setStakedBalanceTwo(expo(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(18),2));
    console.log("Balance = ", balance);

    var mySBalance = parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(3);
    
    balance = await farmContract.pendingToken(id, account);
    setRewardsBalance(parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(3));
    console.log("Balance = ", balance);
    
    var myRBalance = parseFloat(ethers.utils.formatUnits(balance.toString(), values['decimals'])).toFixed(3);
  
    if(+myRBalance > 0)
      counter("rBalance", (+myRBalance - 20), myRBalance, 800);

    if(+mySBalance > 0)          
      counter("sBalance", (+mySBalance - 20), mySBalance, 800);
  }    
  
  
  const getAllowance = async () => {
    const allowance = await tokenContract.allowance(account, farmAddress);
    setAllowance(allowance.toString());
    if(allowance.gt(ethers.utils.parseUnits(stakeInputVal, values['decimals'])) || allowance.eq(ethers.utils.parseUnits(stakeInputVal, values['decimals'])))
    {
      setAllowanceFlag(true);
      setSpinnerVal('');
      setApprove(false);          
    }
  }        
  
  
  const stakeOn = () => {
    console.log("Inside");
    if(stakeOnValue === '')
    {
      setStakeOnValue('1');
      setUnstakeOnValue('');
      setClaimSuccess(false);
      setSuccess(false);
    }
    else
    {
      setStakeOnValue('');
      setUnstakeOnValue('');
      setClaimSuccess(false);
      setSuccess(false);
    }
  }
  
  const unstakeOn = () => {
    if(unstakeOnValue === '')
    {
      setUnstakeOnValue('1');
      setStakeOnValue('');
      setClaimSuccess(false);
      setSuccess(false);
    }
    else
    {
      setStakeOnValue('');
      setUnstakeOnValue('');
      setClaimSuccess(false);
      setSuccess(false);
    }
  }
  
  
  const handleStakeChange = async (e) => {
    e.preventDefault();
    setError('');
    setStakeInputVal(e.target.value);

    setAllowanceFlag(false);
    console.log("Inside Get Allowance = ", e.target.value);
    const allowance = await tokenContract.allowance(account, farmAddress);
    console.log("inside 2");
    setAllowance(allowance.toString());
    if(allowance.gt(ethers.utils.parseUnits(e.target.value, values['decimals'])) || allowance.eq(ethers.utils.parseUnits(e.target.value, values['decimals'])))
    {
      setAllowanceFlag(true);      
    }        

  }


  const handleUnstakeChange = async (e) => {
    e.preventDefault();
    setError('');
    setUnstakeInputVal(e.target.value);
  }      


  const handleStakeMax = async (e) => {

    e.preventDefault();
    setError('');
    setStakeInputVal(tokenbalance);

    setAllowanceFlag(false);
    console.log("Inside Get Allowance = ", tokenbalance);
    const allowance = await tokenContract.allowance(account, farmAddress);
    console.log("inside 2");
    setAllowance(allowance.toString());
    if(allowance.gt(ethers.utils.parseUnits(tokenbalance, values['decimals'])) || allowance.eq(ethers.utils.parseUnits(tokenbalance, values['decimals'])))
    {
      setAllowanceFlag(true);      
    }        


  } 


  const handleUnstakeMax = async (e) => {
    e.preventDefault();
    setError('');
    setUnstakeInputVal(stakedbalance);
    console.log("Max = ", stakedbalance);
  }       
  

  const handleApprove = async (e) => {
    e.preventDefault();
    setError('');
    setStakeInputVal(e.target.value);
    console.log("stakeInputVal = ", stakeInputVal);
    if(!stakeInputVal)
    {
      setError('Please enter correct value.');
    }
    else if(+stakeInputVal <= 0)
    {
      setError('Please enter correct value.');
    }
    else if(stakeInputVal.length === 0 || +stakeInputVal === 0)
    {
      setError('Please enter correct value.');
    }
    else if(ethers.utils.parseUnits(stakeInputVal, values['decimals']).gt(ethers.utils.parseUnits(tokenbalance, values['decimals'])))
    {
      setError('Not enough balance.');
    }
    else
    {
      try {
        setError(''); 
        setSpinnerVal('spinner-border spinner-border-sm mr-1');
        setApprove(true); 
        const tokenVal = ethers.utils.parseUnits(stakeInputVal, values['decimals']);
        const result = await tokenContract.approve(farmAddress, tokenVal);
        await result.wait();
        setAllowance(stakeInputVal);
        getAllowance();
        setStakeInputVal(stakeInputVal);
      } catch (error) {
        console.error(error);
      }        
    }        
  }

  const handleClaim = async (e) => {
    e.preventDefault();
    setError('');
    if(!rewardsbalance)
    {
      setError('Rewards Empty.');
    }
    else if(rewardsbalance.length === 0 || +rewardsbalance === 0)
    {
      setError('Rewards Empty.');
    }
    else
    {  
      setError(''); 
      setSpinnerClaimVal('spinner-border spinner-border-sm mr-1');
      setApprove(true); 

      try {
        setStakeOnValue('');
        setUnstakeOnValue('');
        const result = await farmContract.claim(id);
        await result.wait();
        setClaimSuccess(true);
        setTxValue(values['explorer'] + "tx/" + result.hash);
        setApprove(false);
        setSpinnerClaimVal('');
        getAccount();
      } catch (error) {
        console.error(error);
      }


    }        
  }      
  

  const handleStake = async (e) => {
    e.preventDefault();

    console.log("stakeValue = ", stakeInputVal);
    
    if(!stakeInputVal)
    {
      setError('Please enter correct value.');
    }
    else if(+stakeInputVal <= 0)
    {
      setError('Please enter correct value.');
    }        
    else if(stakeInputVal.length === 0 || +stakeInputVal === 0)
    {
      setError('Please enter correct value.');
    }
    else if(ethers.utils.parseUnits(stakeInputVal, values['decimals']).gt(ethers.utils.parseUnits(tokenbalance, values['decimals'])))
    {
      setError('Not enough balance.');
    }
    else
    {
      setExecute(true);
      setError('');
      setExecuteVal('spinner-border spinner-border-sm mr-1');

      try {
        var tokenVal = ethers.utils.parseUnits(stakeInputVal, values['decimals']);
        const total = await tokenContract.balanceOf(account);
        console.log("TokenVal = ", tokenVal.toString());
        console.log("total = ", total.toString());
        if(total.lt(tokenVal))
          tokenVal = total;
        console.log("TokenVal = ", tokenVal.toString());      
        const result = await farmContract.deposit(id, tokenVal, false);
        await result.wait();
        setSuccess(true);
        setTxValue(values['explorer'] + "tx/" + result.hash);
        setExecute(false);
        setExecuteVal('');
        getAccount();
      } catch (error) {
        console.error(error);
      }

    }
  }



  const handleUnstake = async (e) => {
    e.preventDefault();
    
    if(!unstakeInputVal)
    {
      setError('Please enter correct value.');
    }
    else if(+unstakeInputVal <= 0)
    {
      setError('Please enter correct value.');
    }        
    else if(unstakeInputVal.length === 0 || +unstakeInputVal === 0)
    {
      setError('Please enter correct value.');
    }
    else if(ethers.utils.parseUnits(unstakeInputVal, values['decimals']).gt(ethers.utils.parseUnits(stakedbalance, values['decimals'])))
    {
      setError('Not enough balance.');
    }
    else
    {
      setExecute(true);
      setError('');
      setExecuteVal('spinner-border spinner-border-sm mr-1');

      try {
        var tokenVal = ethers.utils.parseUnits(unstakeInputVal, values['decimals']);
        const total = await farmContract.getDepositAmount(account, id);
        if(total.lt(tokenVal))
          tokenVal = total;
        console.log("Ma2 = ", tokenVal.toString());
        const result = await farmContract.requestWithdraw(id, tokenVal, true);
        await result.wait();
        setSuccess(true);
        setTxValue(values['explorer'] + "tx/" + result.hash);
        setExecute(false);
        setExecuteVal('');
        getAccount();
      } catch (error) {
        console.error(error);
      }

    }
  }      

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)


  return (
    <>
      <div className='farmsClass'>
      <AppBody>
        <Wrapper id="farms-page">
          

              <div className='buttons center'>   

                <div className='myHeader'>
                  <img src="/images/Ciento-Main.png" />
                  <p className='myTitle'>Ciento Exchange</p>
                  <p className='mySub'>Swap Tokens, earn CNTO Rewards for Liquidty Mining and Staking!</p>
                  <p className='mySub'>CNTO Rewards for all New Pools will start from 24th Feb 2023.</p>  
                </div>
        
                { account ? "" :
                  <div className='myUnlockWallet'>
                    <ConnectWalletButton width="100%" />
                  </div>
                }
                
                { chainError === true ?
                  <div className='network_wrong'>Wrong Network. Please choose Acrechain {values['network']} in Metamask and reload this page.
                  </div>
                : "" } 
        
              </div>

              { account && live ?
      <div className='blockMain'>

              <div className='blockMain'>
                <div className='blockShort'>
                    <div className='inside'>
                      <div className='mcapValue'>USD { poolTVL ? poolTVL : <span className="myloadergreen"></span> }</div>
                        <div className='mcapTitle'>TVL at Exchange</div>
                    </div>
                </div>
                <div className='blockShort'>
                    <div className='inside'>
                      <div className='mcapValue'>USD { farmTVL ? farmTVL : <span className="myloadergreen"></span> }</div>
                        <div className='mcapTitle'>Total Staked here</div>
                    </div>
                </div>
                <div className='blockShort'>
                    <div className='inside'>
                      <div className='mcapValue'>USD { userTVL ? userTVL : <span className="myloadergreen"></span> }</div>
                        <div className='mcapTitle'>Your Stake here </div>
                    </div>
                </div>                
              </div>           

        <div className='myAPR'>
        <p>APR: {pOneAPR ? pOneAPR + ' %': <span className="myloaderwhite"></span>}</p>         
        </div> 

        { pOneAPRTwo ? 
                <div className='myHeader'>
                <p className='mySub'>NOTE: APR from 24th Feb will be { pOneAPRTwo } % </p>  
              </div>
        : ''}        


        <div className='blocks myblock'>
          <img src="https://backups.synergynodes.com/images/logos/ciento.png" />
          <p className='rewardsBalance'><p id='rBalance'>{rewardsbalance}</p><span>CNTO Earned</span></p>
          <div className='farmButtons'>

            {+rewardsbalance > 0 ? <button type="button" disabled={approve ? true : false } onClick={handleClaim} className="btn btn-primary claim"> <span className={spinner_claim}></span> Claim Rewards</button> : <button type="button" disabled className="btn btn-primary claim"> Claim Rewards</button>
            }
            
          </div>
        </div>   

        <div className='blocks myblock'>
          <img className='img_left' src={values[tokenAddress].img} />
          <img className='img_right' src={values[tokenAddress].img2} />
          <p className='rewardsBalance'><p id='sBalance'>{stakedbalanceTwo}</p> <span>{values[tokenAddress].name} Staked</span></p>
            <div className='farmButtons'>
              <button className='left' onClick={stakeOn}>Stake</button>
              <button className='right' onClick={unstakeOn}>Unstake</button>
            </div>

        </div>  

        <br></br>

        { claimSuccess ? 
        <div className='myStake'>
          <div className='success'>
            <p className='txHead'>Success!</p>
            <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
          </div>
        </div>
        : "" }

        
        
        { stakeOnValue ?
        <form onSubmit={handleStake} >  
        
        <div className='myStake'>
          <p className='stakeTitle'>Stake {values[tokenAddress].name} Tokens
            <span>Available: {tokenbalance}</span>
          </p>
          <div className='stakeInput'>
            <span className="spanInput"><input id="myVal" type="number" className='inputClass' onChange={handleStakeChange} placeholder="0" value={stakeInputVal} /></span>
            <span className="spanInput">{values[tokenAddress].name}</span>
            <span className="spanInput"><a href="#" onClick={handleStakeMax}>MAX</a></span>
          </div>
          <div>{valerror ? <span className='warning'>{valerror}</span> : '' }</div>
          

          <div className='farmButtons'>
          {(allowanceFlag ? <button type="submit" disabled={execute ? true : false } className="btn btn-primary confirm"><span className={executeVal}></span> Submit</button>: <button type="button" disabled={approve ? true : false } onClick={handleApprove} className="btn btn-primary blue"> <span className={spinner}></span> Approve</button>)}
          </div>        

          { success ? 
            <div className='success'>
              <p className='txHead'>Success!</p>
              <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
            </div>
    
            : "" }           

        </div>   

        </form>  

        : "" }





        { unstakeOnValue ?
          <form onSubmit={handleUnstake} >  
          
          <div className='myStake'>
            <p className='stakeTitle'>Withdraw {values[tokenAddress].name} Tokens
              <span>Staked: {stakedbalance}</span>
            </p>
            <div className='stakeInput'>
              <span className="spanInput"><input type="number" className='inputClass' onChange={handleUnstakeChange} placeholder="0" value={unstakeInputVal} /></span>
              <span className="spanInput">{values[tokenAddress].name}</span>
              <span className="spanInput"><a href="#" onClick={handleUnstakeMax}>MAX</a></span>
            </div>
            <div>{valerror ? <span className='warning'>{valerror}</span> : '' }</div>
            
    
            <div className='farmButtons'>
              <button type="submit" disabled={execute ? true : false } className="btn btn-primary confirm"><span className={executeVal}></span> Confirm</button>
            </div>  
            
            <span className='warning'>NOTE: Tokens will be locked for 1 day.</span>          
    
            { success ? 
              <div className='success'>
                <p className='txHead'>Success!</p>
                <p className='txLink'><a href={txValue} target="_blank">Click Here for Tx Details</a></p>            
              </div>
      
              : "" }           
    
          </div>   
    
          </form>  
    
          : "" }      



      </div>
    : <div></div> }


    <span className='warningTwo'>NOTE: Unbonding period is 1 day.</span> 

  



        </Wrapper>
      </AppBody>
      </div>
    </>
  )
}

export default Farm2
